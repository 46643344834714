import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, TextField, Card } from '@mui/material';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {  useGetAnEmployeeQuery, useUpdateEmployeeMutation } from '../features/employee/employeeApi';
import TransitionAlert from '../components/alert/TransitionAlert';
import SingleSelect from '../components/select/SingleSelect';



// ----------------------------------------------------------------------

export default function UpdateEmployeePage() {
    const { id } = useParams();
    const lstlocation = [{ label:'PEL', value: 'PEL'},{label:'CEC', value: 'CEC'},{label:'QuipTech', value: 'QuipTech'}]
    const { data: employee, isSuccess: isEmployeeSuccess } = useGetAnEmployeeQuery(id);
    const [updateEmployee, { data, isLoading, isSuccess }] = useUpdateEmployeeMutation()
    const initialValues = {
        firstName: employee?.data?.firstName,
        lastName: employee?.data?.lastName,
        email: employee?.data?.email,
        designation: employee?.data?.designation,
        location: employee?.data?.location,
    }
    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string().email("Enter a valid email address").required("Email is required"),
        designation: Yup.string().required("Designation is required"),
        location: Yup.string().required("Location is required"),
    })
    const onSubmit = (values) => {
        updateEmployee({
            ...employee?.data,
            ...values
        });
    }
    const navigate = useNavigate()
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/dashboard/employee");
            }, 1000);
        }
    }, [data?.isSuccess, isSuccess, navigate])


    return isEmployeeSuccess && (
        <>
            <Helmet>
                <title> Edit Employee | PEL Group </title>
            </Helmet>

            <Container sx={{ margin: "0 0" }} maxWidth="md">

                <Typography variant="h3" >
                    Edit Employee
                </Typography>
                {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    <Form>
                        <Card>
                            <Grid px={2} py={4} container spacing={3} >
                                <Grid item xs={12} sm={6} >
                                    <Field name="firstName">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="First Name" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="firstName">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field name="lastName">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Last Name" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="lastName">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="email">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Email address" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="email">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="designation">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Designation" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="designation">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>

                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="location">
                                        {({ field }) => (
                                           <SingleSelect {...field} name="location" label="Location" options={lstlocation} />
                                        )}
                                    </Field>
                                    <ErrorMessage name="location">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>

                                </Grid>
                                <Grid item xs={8} sx={{mt:6}}>
                                    <LoadingButton loading={isLoading} type='submit' fullWidth variant="contained">
                                        Update
                                    </LoadingButton>
                                </Grid>


                            </Grid>
                        </Card>

                    </Form>
                </Formik>
            </Container>
        </>
    );
}
