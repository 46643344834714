import { apiSlice } from "../api/apiSlice";

export const userSettingApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        resetPassword: builder.mutation({
            query: (data) => ({
                url: '/api/userSettings/ResetPassword',
                method: "POST",
                body: data,
            }),
            
        }),
    }),
})


export const { 
    useResetPasswordMutation,
 } = userSettingApi