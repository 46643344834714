import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Box, Button, Container, Typography, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InstructionModal from '../components/modal/InstructionModal';
import { useEditEmployeeRatingMutation, useGetAnEmployeeRatingQuery, useGetEmployeeRatingQuery, useRatingQAQuery, useSelfApprasalQAQuery } from '../features/review/reviewApi';
import EmployeReviewForm from '../sections/@dashboard/review/EmployeReviewForm';
import ReadOnlySection3 from '../sections/@dashboard/performance/ReadOnlySection3';
import ReadOnlyEmployeReview from '../sections/@dashboard/review/ReadOnlyEmployeReview';
import PopUpModal from '../components/modal/PopUpModal';
import UpdateEmployeeReviewFrom from '../sections/@dashboard/review/UpdateEmployeeReviewFrom';
import TransitionAlert from '../components/alert/TransitionAlert';
import { useGetAnTaskQuery } from '../features/tasks/tasksApi';
// ----------------------------------------------------------------------


export default function PerformanceReviewPageForEmployee() {

    const [reviewDetails, setReviewDetails] = useState({})
    const { user } = useSelector((state) => state.auth)
    const { data: selfApprasal, isSuccess: selfSuccess, isLoading: selfIsLoading } = useSelfApprasalQAQuery();
    const { data: RatingQA, isSuccess: ratingSuccess, isLoading: ratingIsLoading } = useRatingQAQuery();
    const [open, setOpen] = useState(false);
    const [openRequest, setOpenRequest] = useState(false);
    const { data: employeeRatingList } = useGetEmployeeRatingQuery();
    const { data: taskData } = useGetAnTaskQuery(user?.id);

    useEffect(() => {

        if (employeeRatingList?.data?.length > 0) {
            const data = employeeRatingList?.data?.find((item) => item.employeeId
                === user?.id)
            setReviewDetails(data)
        }
    }, [employeeRatingList?.data, user?.id])

    const { data: managerRatingInfo, isLoading: isload } = useGetAnEmployeeRatingQuery(reviewDetails?.employeeReviewDetailsId);

    console.log('isEmployeeReviewDetailsId', reviewDetails)

    const [EditEmployeeRating, { data: EditRequestRating, isLoading, isSuccess: isRequestSuccess }] = useEditEmployeeRatingMutation()
    const handleEditRequest = () => {
        EditEmployeeRating({
            employeeReviewDetailsId: reviewDetails?.employeeReviewDetailsId,
            status: "RequestForEdit"
        })
    }
   
    useEffect(() => {
        if (isRequestSuccess && EditRequestRating?.isSuccess) {
            setOpenRequest(false);
            window.location.reload();

        }
    }, [EditRequestRating?.isSuccess, isRequestSuccess])
    return selfSuccess && ratingSuccess && !ratingIsLoading && !selfIsLoading && (
        <>
            <Helmet>
                <title> Performance Review | PEL Group </title>
            </Helmet>
          
            {user?.managerId === 0 ? <Box sx={{ width: "500px", height: '500px', m: '0 auto', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='h5' textAlign={"center"} display={'flex'} justifyContent={"center"}>
                    You don't have any manager assigned. You can't review yourself.
                </Typography>
            </Box> :
                <Container sx={{ margin: 0 }}>
                    {reviewDetails?.disabled && reviewDetails?.employeeReviewDetailsId ?
                        <>
                            {
                                managerRatingInfo?.data?.isManagerReviewSubmitted === false  ? <>
                                    
                                    <UpdateEmployeeReviewFrom task={taskData?.data} draftData={managerRatingInfo?.data} RatingQA={RatingQA} selfApprasal={selfApprasal} setOpen={setOpen} /> </> :
                                    <>
                                        <TransitionAlert msg={"Your review has been submitted"} type={"success"} />

                                        <ReadOnlyEmployeReview id={reviewDetails?.employeeReviewDetailsId} empId={reviewDetails?.employeeId} />
                                        <Typography variant='h5' >
                                            Section 2 -{new Date().getFullYear()} Objectives Appraisal
                                        </Typography>
                                        
                                        {!isload && <ReadOnlySection3  list={managerRatingInfo?.data?.managerSmartTargetQuestions} employeeRatingAvg={managerRatingInfo?.data?.employeeRatingAvg} managerRatingAvg={managerRatingInfo?.data?.managerRatingAvg} />}
                                        {/* {
                                            taskData?.data?.status === 'Deny' && <Typography variant='h5' >
                                            <Box display={"flex"} flexDirection={"column"}>

                                                <TextField
                                                    disabled
                                                    variant="outlined"
                                                    value={taskData?.data?.taskName}
                                                />

                                            </Box>
                                        </Typography>
                                        } */}
                                        
                                        {/* {
                                            (managerRatingInfo?.data?.status !== 'RequestForEdit' && managerRatingInfo?.data !== undefined) &&
                                            <Grid item xs={6} >
                                                <LoadingButton onClick={() => setOpenRequest(true)} variant="contained" fullWidth>Request HR to edit</LoadingButton>
                                            </Grid>
                                        } */}



                                        <PopUpModal
                                            open={openRequest}
                                            setOpen={setOpenRequest}
                                        >
                                            <Typography variant='h5' textAlign={"center"} color={"red"}>
                                                Are you sure?
                                            </Typography>
                                            <Typography variant='subtitle2' textAlign={"center"} mb={3}>
                                                You want to request HR to edit this review.
                                            </Typography>
                                            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                                                <Button onClick={() => setOpenRequest(false)} variant="contained" color='inherit'>No</Button>
                                                <LoadingButton onClick={handleEditRequest} loading={isLoading} variant="contained" >Yes</LoadingButton>
                                            </Box>

                                        </PopUpModal>
                                    </>
                            }

                        </>
                        : <EmployeReviewForm RatingQA={RatingQA} selfApprasal={selfApprasal} setOpen={setOpen} isEmployeeReviewDetailsId={reviewDetails?.employeeReviewDetailsId} />}
                </Container>
            }

            <InstructionModal
                open={open}
                setOpen={setOpen}
            />
        </>
    );
}


