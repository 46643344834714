import React from 'react'
// import { Field } from 'formik';
import { Box, Typography } from '@mui/material'
import SmartTarget from './SmartTarget'
// import DateField from '../../../components/date-picker/DatePicker';

export default function ManagerToSetWithEmploye() {
    const smartTarget2 = [
        {
            id: 0,
            description: "managerSmartTargetQuestions-description-3",
            timeline: "managerSmartTargetQuestions-timeline-3",
            managerComments: "managerSmartTargetQuestions-managerComments-3",
            // employeeRating: "managerSmartTargetQuestions-employeeRating-3",
            // managerRating: "managerSmartTargetQuestions-managerRating-3",
        },
        {
            id: 1,
            description: "managerSmartTargetQuestions-description-4",
            timeline: "managerSmartTargetQuestions-timeline-4",
            managerComments: "managerSmartTargetQuestions-managerComments-4",
            // // employeeRating: "managerSmartTargetQuestions-employeeRating-4",
            // // managerRating: "managerSmartTargetQuestions-managerRating-4",
        },
        {
            id: 2,
            description: "managerSmartTargetQuestions-description-5",
            timeline: "managerSmartTargetQuestions-timeline-5",
            managerComments: "managerSmartTargetQuestions-managerComments-5",
            // // employeeRating: "managerSmartTargetQuestions-employeeRating-5",
            // // managerRating: "managerSmartTargetQuestions-managerRating-5",
        }

    ]
    return (
        <Box bgcolor={"#f5f5f5"} p={2}>
            <Box border={"1px solid #ddd"} borderRadius={1} p={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="subtitle1" gutterBottom component="p">
                    Outline smart objectives for achievement for next year.
                </Typography>
            </Box>
            {smartTarget2.map((data, key) =>
                <SmartTarget key={key} data={data} />
            )}

            {/* <TableContainer component={Paper}  >
                <Table aria-label="simple table">

                    <TableBody>
                        <TableRow>
                            <TableCell align="left">Employee signature:</TableCell>
                            <TableCell align="left">
                                <Field name="employeeSign">
                                    {({ field }) => (
                                        <TextField label="signature" variant="outlined" required {...field} />
                                    )}
                                </Field>
                            </TableCell>
                            <TableCell align="left">Date: </TableCell>
                            <TableCell align="left">
                                <DateField name='employeeDate' />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Manager /Appraiser signature(s):</TableCell>
                            <TableCell align="left">
                                <Field name="managerSign">
                                    {({ field }) => (
                                        <TextField label="signature" variant="outlined" required {...field} />
                                    )}
                                </Field>
                            </TableCell>
                            <TableCell align="left">Date: </TableCell>
                            <TableCell align="left">

                                <DateField name='managerDate' />

                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">Hr /Appraiser signature(s):</TableCell>
                            <TableCell align="left">
                                <Field name="hrSign">
                                    {({ field }) => (
                                        <TextField label="signature" variant="outlined" required {...field} />
                                    )}
                                </Field>
                            </TableCell>
                            <TableCell align="left">Date: </TableCell>
                            <TableCell align="left">
                                <DateField name='hrDate' />
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer> */}

        </Box>
    )
}
