import { apiSlice } from "../api/apiSlice";

const url = "/api/Company"
const urlEndDateExpired = "/api/Company/EndDateExpired"


export const companyApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addCompany: builder.mutation({
            query: (data) => ({
                url,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Company","AllCompany"],
            async onQueryStarted() {
                try {

                    // if (result.data.isSuccess) {
                    // toast.success(result?.data?.message)
                    // }
                    // if (!result.data.isSuccess) {
                    // toast.warning(result?.data?.message)
                    // }

                } catch (err) {
                    // do nothing 
                }
            }
        }),
        getCompany: builder.query({
            query: () => url,
            method: "GET",
            keepUnusedDataFor: 600,
            providesTags: ["Company"],
        }),
        getIDCompany: builder.query({
            query: (id) => `${url}/${id}`,
            keepUnusedDataFor: 600,
        }),
        endDateExpired: builder.mutation({
            query: (data) => ({
                url: '/api/Company/EndDateExpired',
                method: "PUT",
                body: data,
            }),
            async onQueryStarted() {
                try {

                    // if (result.data.isSuccess) {
                    // toast.success(result?.data?.message)
                    // }
                    // if (!result.data.isSuccess) {
                    // toast.warning(result?.data?.message)
                    // }

                } catch (err) {
                    // error
                }
            }
        }),
       
    }),
})


export const {
    useAddCompanyMutation,
    useGetCompanyQuery,
    useGetIDCompanyQuery,
    useEndDateExpiredMutation,

 } = companyApi