import { Helmet } from 'react-helmet-async';
import {  Container, Typography, Button } from '@mui/material';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useGetAllEmployeesQuery } from '../features/employee/employeeApi';
import TransitionAlert from '../components/alert/TransitionAlert';
import { useAddOrganizationMutation } from '../features/organization/organizationApi';
import SingleSelect from '../components/select/SingleSelect';




// ----------------------------------------------------------------------

const AddOrganizationForm = ({ options }) => {
    const { user } = useSelector((state) => state.auth)
    const [addOrganization, { data, isLoading, isSuccess }] = useAddOrganizationMutation()
    const initialValues = {
        employee: '',
    }
    const validationSchema = Yup.object({
        employee: Yup.string(),
    })
    const onSubmit = (values) => {
        addOrganization({ ...values, organizationId: user?.id });
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/dashboard/organisation");
                enqueueSnackbar(data?.message, { variant: 'success' });
            }, 1000);
        }
    }, [data?.isSuccess, data?.message, enqueueSnackbar, isSuccess, navigate])
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            <Form>
                {!data?.isSuccess && isSuccess && <TransitionAlert msg={data?.message} type='error' />}
                <Box>
                    <SingleSelect name="employee" options={options}  label="Employees Name" />
                </Box>

                <Box display={"flex"} justifyContent={"flex-end"} gap={2} width={"100%"}>
                    <Button variant="contained" sx={{ mt: 2, mb: 1, ml: 2 }} onClick={() => navigate("/dashboard/organisation")}>
                        Back
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ mt: 2, mb: 1 }}>
                        Add
                    </LoadingButton>

                </Box>
            </Form>
        </Formik>
    )
}
AddOrganizationForm.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })),
}

// ----------------------------------------------------------------------

export default function AddOrganisationPage() {

    const { data } = useGetAllEmployeesQuery()
    const managers = data?.data?.filter((item) => item?.userType === "E" && item?.userType !== 'M'  )
    const options = managers?.map((item) => ({ label: `${item.fullName}`, value: item?.userId }))






    return (
        <>
            <Helmet>
                <title> Add Manager | PEL Group </title>
            </Helmet>

            <Container sx={{ margin: "0 0" }} maxWidth="md">

                <Typography variant="h3" >
                    Add Manager
                </Typography>
                {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
                <AddOrganizationForm options={options} />
            </Container>
        </>
    );
}
