// import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";


export const managerApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getAnManagerRating: builder.query({
            query: (id) => `/api/Manager/Rating/${id}`,
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => [{ type: "ManagerRating", id: arg?.id }],
        }),

       managerRating: builder.mutation({
            query: (data) => ({
                url: '/api/Manager/Rating',
                method: "POST",
                body: data,
            }),
           invalidatesTags: ["ManagerRating"],
            async onQueryStarted() {
                try {
                    

                    // if (result.data.isSuccess) {
                    // toast.success(result?.data?.message)
                    // }
                    // if (!result.data.isSuccess) {
                    // toast.warning(result?.data?.message)
                    // }

                } catch (err) {
                    // do nothing 
                }
            }
        }),
       updateManagerRating: builder.mutation({
            query: (data) => ({
                url: '/api/Manager/Rating',
                method: "PUT",
                body: data,
            }),
           invalidatesTags: (result, error, arg) => [{ type: "ManagerRating", id: arg?.id }, { type: "EmployeeRating", id: arg?.id }],
          
        }),

    }),
})


export const {
    useGetAnManagerRatingQuery,
    useManagerRatingMutation,
    useUpdateManagerRatingMutation,
} = managerApi