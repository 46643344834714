import { Box, Button, CardActions, Grid, Stack, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ReactRating from '../../../components/rating/ReactRating'
import { useEmployeeRatingMutation } from '../../../features/review/reviewApi'
import TransitionAlert from '../../../components/alert/TransitionAlert'

import { ObjectiveAppraisalForEmployee } from '../performance'
import PopUpModal from '../../../components/modal/PopUpModal'

EmployeReviewForm.propTypes = {
    selfApprasal: PropTypes.object,
    RatingQA: PropTypes.object,
    setOpen: PropTypes.func,
    reviewDetailsId: PropTypes.number

};
export default function EmployeReviewForm({ selfApprasal, RatingQA, setOpen }) {

    const [draft, setDraft] = React.useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [employeeRating, { data, isLoading, isSuccess }] = useEmployeeRatingMutation();

    const initialvaluesSelfApprasal = selfApprasal?.data?.reduce((acc, curr) => ({
        ...acc,
        [`selfApprasal-${curr?.selfAppraisalQuestionId}`]: "",
    }), {});
    const initialvaluesEmployeeRate = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRate-${curr?.reviewRatingQuestionId}`]: 0,
    }), {});
    const initialvaluesEmployeeRateComment = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRateComment-${curr?.reviewRatingQuestionId}`]: "",
    }), {});
    const smartTarget = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: "",
        [`managerSmartTargetQuestions-timeline-${curr}`]: "",
        [`managerSmartTargetQuestions-employeeRating-${curr}`]: 0,
    }), {});
    const initialvalues = {
        ...initialvaluesSelfApprasal,
        ...initialvaluesEmployeeRate,
        ...initialvaluesEmployeeRateComment,
        ...smartTarget,
        areasIdentifiedforTrainingDevelopment: "",
    }

    // create validation schema dynamically using the questions array
    const selfSchema = selfApprasal?.data?.reduce((acc, curr) => ({
        ...acc,
        [`selfApprasal-${curr?.selfAppraisalQuestionId}`]: yup.string().required("This field is required!"),
    }), {})
    const ratingSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRate-${curr?.reviewRatingQuestionId}`]: yup.number().min(1, "This field is required!").max(5, "This field is required!").integer().required("This field is required!"),
    }), {});
    const commentSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRateComment-${curr?.reviewRatingQuestionId}`]: yup.string().required("This field is required!"),
    }), {});
    const smartTargetSchema = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-timeline-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-employeeRating-${curr}`]: yup.number().min(1, "This field is required!").max(5, "This field is required!").integer().required("This field is required!")
    }), {});
    const validationSchema = yup.object({
        ...selfSchema,
        ...ratingSchema,
        ...commentSchema,
        ...smartTargetSchema,
        areasIdentifiedforTrainingDevelopment: yup.string().required("Required"),
    });


    const onSubmit = (values) => {

        let employeeSelfApprasalQAs = Object.keys(values).filter((key) => key.includes("selfApprasal"))
        employeeSelfApprasalQAs = employeeSelfApprasalQAs.map((key) => key.includes("selfApprasal") && {
            selfAppraisalQuestionId: key.split("-")[1],
            answer: values[key],

        })
        // const employeeRate = Object.keys(values).filter((key) => key.includes("employeeRate"))
        const employeeRateComment = Object.keys(values).filter((key) => key.includes("employeeRateComment"))

        const employeeRatingModels = employeeRateComment.map((key) => ({
            reviewRatingQuestionId: key.split("-")[1],
            employeeRating: values[`employeeRate-${key?.split("-")[1]}`],
            employeeComment: values[`employeeRateComment-${key?.split("-")[1]}`],
        }))

        const managerSmartTargetQuestionsDescription = Object.keys(values).filter((key) => key.includes("managerSmartTargetQuestions-description"));

        const managerSmartTargetQuestions = managerSmartTargetQuestionsDescription.map((key) => ({
            reviewSmartTargetQuestionId: key.split("-")[2],
            description: values[`managerSmartTargetQuestions-description-${key?.split("-")[2]}`],
            timeline: values[`managerSmartTargetQuestions-timeline-${key?.split("-")[2]}`],
            employeeRating: values[`managerSmartTargetQuestions-employeeRating-${key?.split("-")[2]}`],
        }))

        employeeRating({
            areasIdentifiedforTrainingDevelopment: values?.areasIdentifiedforTrainingDevelopment,
            status: draft ? "Draft" : "Submitted",
            dateofReview: new Date(),
            employeeSelfApprasalQAs,
            employeeRatingModels,
            managerSmartTargetQuestions,
        });
    }
    const navigate = useNavigate();
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setDraft(false);
            setTimeout(() => {
                navigate("/dashboard/performance-review");
            }, 1000);
        }
    }, [data?.isSuccess, isSuccess, navigate])

    const OpeninstructionPDF = (ele) => {
        if (ele !== null && ele.target.name === 'PdfOpen') {
            window.open('/assets/PDF/instruction.pdf');
        }
    }

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = (setFieldValue) => {
        setFieldValue(currentField, dialogContent)
        setOpenDialog(false);
    };

    return (
        <Formik
            initialValues={initialvalues}
            validationSchema={draft ? null : validationSchema}
            onSubmit={onSubmit}
        >
            {
                ({ handleSubmit, setFieldValue }) => (
                    <Form>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Box>

                                {/* 
                        <Typography variant="h4" >
                            2023 H1
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 5 }}>
                            Please complete the below
                        </Typography> */}
                            </Box>
                            <Button name="PdfOpen" onClick={(e) => OpeninstructionPDF(e)} variant="contained" >
                                instruction
                            </Button>
                        </Stack>
                        <Stack spacing={2} width={"100%"}>
                            <Typography variant="h5" color="initial">
                                Self Appraisals
                            </Typography>

                            {selfApprasal?.data?.map((data) =>
                                <Box key={data?.selfAppraisalQuestionId}>
                                    <Field name={`selfApprasal-${data?.selfAppraisalQuestionId}`}>
                                        {({ field, form }) => (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                                                    {...field}
                                                    variant="outlined"
                                                />

                                                <Box onClick={() => handleClickOpen(`selfApprasal-${data?.selfAppraisalQuestionId}`, field.value, `${data?.selfAppraisalQuestionId}. ${data?.question}`, data?.question, true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                </Box>
                                            </Box>

                                        )}
                                    </Field>
                                    {/* {errors.exampleRequired && <span>This field is required</span>}
                                     */}
                                    <ErrorMessage name={`selfApprasal-${data?.selfAppraisalQuestionId}`} >
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Box>
                            )}


                        </Stack>
                        <Grid container spacing={3} mt={5}>
                            <Grid item xs={12}>
                                {RatingQA?.data?.map((data, key) =>
                                    <Stack
                                        key={key}
                                        mt={2}
                                        alignItems={'center'}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 1, sm: 1, md: 1 }}
                                    >
                                        <Box maxWidth={"550px"} width={"100%"} >
                                            <Typography variant='subtitle1'> {data.question} </Typography>
                                            <Typography variant='body2'> {data.description} </Typography>
                                        </Box>
                                        <Box maxWidth={"180px"} width={"100%"}>
                                            <Field name={`employeeRate-${data?.reviewRatingQuestionId}`}>
                                                {({ form, field }) => {
                                                    const { setFieldValue } = form;
                                                    const { value } = field;
                                                    return (
                                                        <ReactRating
                                                            // onChange={value => handleRating(value, data?.reviewRatingQuestionId)}
                                                            initialRating={value}
                                                            onChange={(val) => setFieldValue(`employeeRate-${data?.reviewRatingQuestionId}`, val)}
                                                            value={data.Rating} />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name={`employeeRate-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                        <Box width={"33%"}>

                                            <Field name={`employeeRateComment-${data?.reviewRatingQuestionId}`}>
                                                {({ field, form }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                            fullWidth
                                                            multiline
                                                            rows={"2"}
                                                            placeholder={data?.Description}
                                                            label="Comments"
                                                            variant="outlined"
                                                            {...field}
                                                        />
                                                        <Box onClick={() => handleClickOpen(`employeeRateComment-${data?.reviewRatingQuestionId}`, field.value, 'Comments', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>

                                                )}
                                            </Field>
                                            <ErrorMessage name={`employeeRateComment-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                    </Stack>
                                )}
                            </Grid>

                        </Grid>

                        <Typography variant='h5' >
                            Section 2 - Objectives Appraisal
                        </Typography>
                        <ObjectiveAppraisalForEmployee setFieldValue={setFieldValue} />

                        <Grid container spacing={3} my={5}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'> Areas Identified for Training & Development - </Typography>
                                <Field name="areasIdentifiedforTrainingDevelopment">
                                    {({ field, form }) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                fullWidth
                                                multiline
                                                rows={"2"}
                                                {...field}
                                            />
                                            <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopment', field.value, 'Areas Identified for traning and Development', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                            </Box>
                                        </Box>
                                    )}
                                </Field>
                                <ErrorMessage name="areasIdentifiedforTrainingDevelopment">
                                    {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                </ErrorMessage>

                            </Grid>
                        </Grid>

                        <Grid style={{ marginLeft: "20%", }} container spacing={3} my={5}>
                            <Grid item xs={3} >
                                <Box>
                                    <LoadingButton type='button' onClick={() => setDraft(true)} color='inherit' variant="contained" fullWidth> Draft </LoadingButton>
                                </Box>
                            </Grid>
                            <Grid item xs={3} >
                                <Box>
                                    <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth> Submit </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>


                        <PopUpModal open={draft} setOpen={setDraft}>
                            <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2" mb={2}>
                                Are you sure you want to save as draft?
                            </Typography>
                            <CardActions>
                                <Button type='button' onClick={() => setDraft(false)} color='inherit' fullWidth variant="contained">
                                    Cancel
                                </Button>
                                <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit} fullWidth> save as draft </LoadingButton>
                            </CardActions>
                        </PopUpModal>
                        {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}

                        <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                            <DialogTitle>{dialogTitle}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={dialogContent}
                                    onChange={(e) => setDialogContent(e.target.value)}
                                    variant="outlined"
                                    disabled={!isEditable}
                                    InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">Close</Button>
                                {isEditable && <Button onClick={() => handleSave(setFieldValue)} color="primary">Save</Button>}
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }
        </Formik >
    );
}
