import PropTypes from 'prop-types';
import { useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// @mui
import {
  Card,
  Stack,
  CardHeader,
  Box,
  Typography, Grid, Container, CardActions, TextField
  // IconButton,
} from '@mui/material';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import { TimelineContent, TimelineDot, LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';

// components
// import Iconify from '../../../components/iconify';
import { useUpdateTaskMutation } from '../../../features/tasks/tasksApi';
import BasicModal from '../../../components/modal/BasicModal';


// ----------------------------------------------------------------------

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,

};

export default function AppTasks({ title, subheader, list, user, ...other }) {
  const { control } = useForm({
    defaultValues: {
      taskCompleted: ['2'],
    },
  });

  const [id, setId] = useState(null);

  const handleReopen = (event, id) => {
    if (event !== null && event.target.name === 'checked') {
      console.log(event);
      setId(id);
    }
    // setChecked({ ...task, reopen: true });

  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={16} md={8} >
          <Card {...other}>
            <CardHeader title={title} subheader={subheader} />
            <Controller
              name="taskCompleted"
              control={control}
              render={({ field }) => {
                const onSelected = (task) =>
                  field.value.includes(task) ? field.value.filter((value) => value !== task) : [...field.value, task];

                return (
                  <>

                    {list?.map((task, key) => (
                      <Stack
                        direction="row"
                        sx={{
                          px: 3,
                          py: 0.75,


                        }}
                      >
                        <Box
                          sx={{ flexGrow: 1, m: 0 }}
                          display={"flex"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Typography maxWidth={"130px"} width={"100%"} variant="body1" sx={{ color: 'text.secondary' }}>
                            {task?.assignedToName}
                          </Typography>

                          {/* <Box onClick={handleReopen} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <TimelineDot variant="outlined" color="primary" />
                          <TimelineContent variant="body2">{task?.status}</TimelineContent>
                          </Box> */}
                          <LoadingButton type="submit" variant="contained" name="checked" sx={{ float: 'right', mr: 5 }} onClick={(event) => handleReopen(event, task.taskId)}>
                            {task?.status}
                          </LoadingButton>
                          {/* {task?.status === "Pending" && <Box onClick={handleOpenMenu} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <TimelineDot variant="outlined" color="primary" />
                          <TimelineContent variant="body2">{"Complete"}</TimelineContent>
                          </Box>} */}


                        </Box>
                        <Typography variant="subtitle1" sx={{ color: 'red' }}>
                          {/* 10/12/2023 */}
                          {task?.createdDate.split(' ')[0]}
                        </Typography>

                        {/* <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={handleOpenMenu}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton> */}


                      </Stack>
                    ))}




                  </>
                );
              }}
            />

            <Typography variant="subtitle2" sx={{ color: 'blue', textAlign: "center", cursor: "pointer", mb: 2 }}>
              <NavLink to="/dashboard/task" style={{ textDecoration: 'none' }}>
                {"See all tasks"}
              </NavLink>
            </Typography>
          </Card>

        </Grid>

        {id !== null && list.find(x => x.taskId === id).status === 'Request To Open' ? <><Grid item xs={13} md={4} >
          <UpdateTask id={id} item={list.find(x => x.taskId === id)} user={user} />
        </Grid>
        </> : <></>
        }

      </Grid>

    </>

  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.shape({
    assignedTo: PropTypes.number,
    assignedToName: PropTypes.string,
    status: PropTypes.string,
    taskName: PropTypes.string,
  }),
};

function TaskItem({ user, task }) {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState({});

  const handleOpenMenu = () => {
    setChecked(task)
    setOpen(true);
  };
  const handleReopen = (event) => {
    if (event !== null && event.target.name === 'checked') {
      <AppTasks />
    }
    // setChecked({ ...task, reopen: true });

  };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  // const handleMarkComplete = () => {
  //   handleCloseMenu();
  //   console.log('MARK COMPLETE', task.id);
  // };

  // const handleShare = () => {
  //   handleCloseMenu();
  //   console.log('SHARE', task.id);
  // };

  // const handleEdit = () => {
  //   handleCloseMenu();
  //   console.log('EDIT', task.id);
  // };

  // const handleDelete = () => {
  //   handleCloseMenu();
  //   console.log('DELETE', task.id);
  // };

  return (
    <Stack
      direction="row"
      sx={{
        px: 3,
        py: 0.75,


      }}
    >
      <Box
        sx={{ flexGrow: 1, m: 0 }}
        display={"flex"}
        alignItems={"center"}
        gap={2}
      >
        <Typography maxWidth={"130px"} width={"100%"} variant="body1" sx={{ color: 'text.secondary' }}>
          {task?.assignedToName}
        </Typography>

        {/* <Box onClick={handleReopen} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <TimelineDot variant="outlined" color="primary" />
          <TimelineContent variant="body2">{task?.status}</TimelineContent>
        </Box> */}
        <LoadingButton type="submit" variant="contained" name="checked" sx={{ float: 'right', mr: 5 }} onClick={(event) => handleReopen(event)}>
          {task?.status}
        </LoadingButton>
        {/* {task?.status === "Pending" && <Box onClick={handleOpenMenu} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <TimelineDot variant="outlined" color="primary" />
          <TimelineContent variant="body2">{"Complete"}</TimelineContent>
        </Box>} */}


      </Box>
      <Typography variant="subtitle1" sx={{ color: 'red' }}>
        {/* 10/12/2023 */}
        {task?.createdDate.split(' ')[0]}
      </Typography>

      {/* <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={handleOpenMenu}>
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton> */}

      <BasicModal open={open} setOpen={setOpen} task={checked} />
    </Stack>
  );
}


function UpdateTask({ id, item, user }) {
  const [UpdateTask, { data, isLoading, isSuccess }] = useUpdateTaskMutation();
  const initialValues = {
    taskName: '',
    status: ''
  }

  const validationSchema = Yup.object({
    taskName: Yup.string().required("Task Name is required"),
  })

  const [taskForm, setTaskForm] = useState({
    taskId: 0,
    taskName: null,
    status: null,
    createdDate: null
  });
  const [updateSatus, setUpdate] = useState(null);

  const onDenay = (event) => {
    if (event !== null && (event.target.name === 'Deny' || event.target.name === 'Complete'))
      UpdateTask({
        ...taskForm,
        taskId: item?.taskId,
        status: event.target.name,
        createdDate: item?.createdDate
      });

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
  const onSubmit = (values) => {
    UpdateTask({
      ...values,
      taskId: item?.taskId,
      status: 'Complete'
    })
  }



  return (

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      <Form>
        <Card sx={{ width: 450 }}>
          <CardHeader sx={{ textAlign: 'center' }} title={`Request is open for ${item?.assignedToName}`} />
          <Grid item xs={9} sx={{ ml: 6, mt:2 }} >
            <Field name="taskName">
              {({ field }) => (
                <TextField {...field} required fullWidth label="Comment" value={taskForm.taskName} onChange={(e) => setTaskForm({ ...taskForm, taskName: e.target.value })} />
              )}
            </Field>
            {/* <ErrorMessage name="taskName">
                          {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                      </ErrorMessage> */}
          </Grid>
          <CardActions sx={{mt:2}}>

            <Grid item xs={3}  >

              <Button variant="contained" type='submit' name="Deny" sx={{ ml: 6 }} onClick={(event) => onDenay(event)}>
                Deny
              </Button>
            </Grid>
            <Grid item xs={10}  >
              <LoadingButton type="submit" variant="contained" name="Complete" sx={{ float: 'right', mr: 5 }} onClick={(event) => onDenay(event)}>
                Approve
              </LoadingButton>




            </Grid>


          </CardActions>

        </Card>
      </Form></Formik>


  )

}
