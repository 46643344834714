import React from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';


import { useGetAnEmployeeRatingQuery, useGetEmployeeRatingQuery } from '../../../features/review/reviewApi'
import SmartTargetForManager from './SmartTargetForManager'

ObjectiveAppraisal.propTypes = {
    empId: PropTypes.number,
    managerRatingAvg: PropTypes.number,
    employeeRatingAvg: PropTypes.number,
}

export default function ObjectiveAppraisal({ reviewreating,empId, employeeRatingAvg, managerRatingAvg }) {

    const { data } = useGetEmployeeRatingQuery()
    const result = data.data?.find(item => item.employeeId === empId)
    const { data: managerRatingInfo, isLoading, isError, error } = useGetAnEmployeeRatingQuery(result?.employeeReviewDetailsId);
    console.log("ObjectiveAppraisal :",managerRatingInfo);

    const smartTargets = [
        {
            id: 0,
            description: "managerSmartTargetQuestions-description-0",
            timeline: "managerSmartTargetQuestions-timeline-0",
            managerComments: "managerSmartTargetQuestions-managerComments-0",
            employeeRating: "managerSmartTargetQuestions-employeeRating-0",
            managerRating: "managerSmartTargetQuestions-managerRating-0",
        },
        {
            id: 1,
            description: "managerSmartTargetQuestions-description-1",
            timeline: "managerSmartTargetQuestions-timeline-1",
            managerComments: "managerSmartTargetQuestions-managerComments-1",
            employeeRating: "managerSmartTargetQuestions-employeeRating-1",
            managerRating: "managerSmartTargetQuestions-managerRating-1",
        },
        {
            id: 2,
            description: "managerSmartTargetQuestions-description-2",
            timeline: "managerSmartTargetQuestions-timeline-2",
            managerComments: "managerSmartTargetQuestions-managerComments-2",
            employeeRating: "managerSmartTargetQuestions-employeeRating-2",
            managerRating: "managerSmartTargetQuestions-managerRating-2",
        }

    ]

    let content = null;
    if (isLoading) {
        content = <div>Loading...</div>
    }
    if (!isLoading && isError) {
        content = <p>{error}</p>
    }
    if (!isLoading && !isError && managerRatingInfo?.data?.managerSmartTargetQuestions?.length === 0) {
        content = <p>No Found data</p>
    }
    if (!isLoading && !isError && managerRatingInfo?.data?.managerSmartTargetQuestions?.length > 0) {
        content = smartTargets.map((data, key) => <SmartTargetForManager employee={managerRatingInfo?.data?.managerSmartTargetQuestions.filter(x=>x.employeeRating !==0)[key]} key={key} data={data} />)
    }

    return (
        <Box bgcolor={"#f5f5f5"} p={2}>
            {content}

            <TableContainer component={Paper}  >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell align="center">Your Rating</TableCell>
                            <TableCell align="center">Manager Rating</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>

                            <TableCell align="center">{reviewreating?.managerRatingModels?.find(x=>x.reviewRatingQuestionId === 9)?.employeeRating}</TableCell>
                            { reviewreating?.managerRatingModels?.find(x=>x.reviewRatingQuestionId === 9)?.managerRating === 0 ?
                            <>
                            <TableCell align="center">{}</TableCell>
                            </>: <TableCell align="center">{reviewreating?.managerRatingModels?.find(x=>x.reviewRatingQuestionId === 9)?.managerRating}</TableCell>
                            }
                            

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}
