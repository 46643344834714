
import { Link } from 'react-router-dom';
// @mui
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useForgotPasswordMutation } from '../../../features/auth/authApi';
import TransitionAlert from '../../../components/alert/TransitionAlert';

// components
// import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
    const [forgotPassword, { data }] = useForgotPasswordMutation()
    const initialValues = {
        email: ''
    }
    const validationSchema = Yup.object({
        email: Yup.string().email("Enter a valid email address").required("email is required")
    })
    const onSubmit = (values) => {
        forgotPassword(values);
    }
    // const navigate = useNavigate()
    // useEffect(() => {
    //     if (data?.isSuccess && isSuccess) {
    //         setTimeout(() => {
    //             navigate("/me");
    //         }, 1000);
    //     }
    // }, [data?.isSuccess, isSuccess, navigate])
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            <Form>
                {data?.message && <TransitionAlert msg={data?.message} type={data?.isSuccess ? "success" : "warning"} />}
                <Stack spacing={3}>
                    <Field name="email">
                        {({ field }) => (
                            <TextField {...field} label="Email address" />
                        )}
                    </Field>
                    
                    <ErrorMessage name="email">
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember" label="Remember me" /> */}
                    <Typography component={Link} to={"/auth/login"} variant="subtitle2" underline="hover">
                        Go to Login
                    </Typography>
                </Stack>

                <LoadingButton sx={{ mt: 1 }} fullWidth size="large" type="submit" variant="contained">
                    Continue
                </LoadingButton>
            </Form>
        </Formik>
    );
}
