import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardPage from './pages/DashboardPage';
import PreviousReviewPage from './pages/PreviousReviewPage';
import OrganisationPage from './pages/OrganisationPage';
import AddOrganisationPage from './pages/AddOrganisationPage';
import PerformanceReviewPage from './pages/PerformanceReviewPage';
import ForgotPage from './pages/ForgotPage';
import PerformanceReviewPageForManager from './pages/PerformanceReviewPageForManger';
import ResetPage from './pages/ResetPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import EmployeePage from './pages/EmployeePage';
import AddEmployeePage from './pages/AddEmployeePage';
import UpdateEmployeePage from './pages/UpdateEmployeePage';
import UpdateOrganization from './pages/UpdateOrganization';
import TaskPage from './pages/TaskPage';
import AddTaskPage from './pages/AddTaskPage';
import UpdateTaskPage from './pages/UpdateTaskPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardPage /> },
        // ADMIN ROUTES
        { path: 'previous-reviews', element: <PreviousReviewPage /> },
        {
          path: 'task',
          children: [
            { element: <TaskPage />, index: true },
            { path: "add", element: <AddTaskPage /> },
            { path: "update/:id", element: <UpdateTaskPage /> },
          ]
        },
        {
          path: 'organisation',
          children: [
            { element: <OrganisationPage />, index: true },
            { path: "add", element: <AddOrganisationPage /> },
            { path: "update/:id", element: <UpdateOrganization /> },
          ]
        },
        // {
          //   path: 'performance-review',
          //   children: [
        //     { element: <PerformanceReviewPage />, index: true },
        //     { path: "add", element: <PerformanceReviewPage /> },
        //   ]
        // },
        { path: 'performance-review-manager', element: <PerformanceReviewPageForManager /> },
        {
          path: 'employee', children: [
            { element: <EmployeePage />, index: true },
            { path: "add", element: <AddEmployeePage /> },
            { path: "update/:id", element: <UpdateEmployeePage /> },
          ]
        },
        // EMPLOYEE ROUTES
        { path: 'performance-review', element: <PerformanceReviewPage /> },
        {
          path: 'account', children: [
            { element: <Navigate to="/dashboard/account/reset-password" />, index: true },
            { path: 'reset-password', element: <ResetPasswordPage /> },
          ]
        },
      ],
    },
    {
      path: '/auth',
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/auth/login" />, index: true },
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPage />,
        },
        {
          path: 'reset-password',
          element: <ResetPage />,
        },
      ]
    },
    {
      path: '/',
      children: [
        { element: <Navigate to="/auth/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
