import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    password: undefined,
}

const userSettingSlice = createSlice({
    name: "userSetting",
    initialState,
    reducers: {
        setpassword: (state, action) => {
            state.password = action.payload;
            localStorage.setItem("userSetting", JSON.stringify({
                password: action.payload,
            }))
        }

    }
})

export const { setpassword } = userSettingSlice.actions
export default userSettingSlice.reducer