import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, TextField, Card, MenuItem } from '@mui/material';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import TransitionAlert from '../components/alert/TransitionAlert';
import { useAddTaskMutation } from '../features/tasks/tasksApi';
import { useGetEmployeeQuery } from '../features/employee/employeeApi';



// ----------------------------------------------------------------------

export default function AddTaskPage() {
    // const theme = useTheme();
    const { data: employees } = useGetEmployeeQuery()
    const [addTask, { data, isLoading, isSuccess }] = useAddTaskMutation()
    const initialValues = {
        taskName: '',
        assignedTo: '',
        status: '',
    }
    const validationSchema = Yup.object({
        taskName: Yup.string().required("Task Name is required"),
        assignedTo: Yup.string().required("Assigned To is required"),
        status: Yup.string().required("Status is required"),
    })
    const onSubmit = (values) => {
        addTask({
            userId: 1,
            ...values
        });
    }
    const navigate = useNavigate()
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/dashboard/task");
            }, 1000);
        }
    }, [data?.isSuccess, isSuccess, navigate])


    return (
        <>
            <Helmet>
                <title> Add New Task | PEL Group </title>
            </Helmet>

            <Container sx={{ margin: "0 0" }} maxWidth="md">

                <Typography variant="h3" >
                    Add New Task
                </Typography>
                {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    <Form>
                        <Card>
                            <Grid px={2} py={4} container spacing={3} >
                                <Grid item xs={12} >
                                    <Field name="taskName">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Task Name" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="taskName">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="status">
                                        {({ field }) => (
                                            <TextField
                                                fullWidth
                                                select
                                                label="Status"
                                                {...field}
                                            >
                                                {["Pending"].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    </Field>
                                    <ErrorMessage name="status">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="assignedTo">
                                        {({ field }) => (
                                            <TextField
                                                select
                                                label="Assigned To"
                                                fullWidth
                                                {...field}
                                            >
                                                {employees?.data?.map((option) => (
                                                    <MenuItem key={option.userId} value={option.userId}>
                                                        {option.firstName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    </Field>
                                </Grid>

                                
                                <Grid item xs={8}>
                                    <LoadingButton loading={isLoading} type='submit' fullWidth variant="contained">
                                        Add
                                    </LoadingButton>
                                </Grid>


                            </Grid>
                        </Card>

                    </Form>
                </Formik>
            </Container>
        </>
    );
}
