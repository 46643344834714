import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import Iconify from '../iconify/Iconify';

TransitionAlert.propTypes = {
    msg: PropTypes.string,
    type: PropTypes.string,
};

export default function TransitionAlert({ msg, type = "success" }) {
    const [open, setOpen] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setOpen(false)
        }, 6000);
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={open}>
                <Alert
                    severity={type}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <Iconify icon="material-symbols:close" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {msg}
                </Alert>
            </Collapse>
        </Box>
    );
}