import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Paper, Stack, TextField, Typography, Box , Dialog, DialogActions, DialogContent, DialogTitle, Button, } from '@mui/material'

import ReactRating from '../../../components/rating/ReactRating'

ReadOnlySmartTarget.propTypes = {
    data: PropTypes.object,
    yourRating: PropTypes.bool,
    managerRating: PropTypes.bool,
}

export default function ReadOnlySmartTarget({ data, yourRating = true, managerRating = true }) {


    const [open, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [formData, setFormData] = useState();

    const handleClickOpen = (field, content, title, editable) => {
        setCurrentField(field);
        setDialogTitle(title);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {

        const updatedData = formData.map(item => {
            if (item[currentField] !== undefined) {
                return { ...item, [currentField]: dialogContent };
            }
            return item;
        });

        setFormData(updatedData);
        setOpenDialog(false);
    };



    return (
        <Paper sx={{ my: 5, border: "1px solid #ddd", p: 2 }}>
            <Stack spacing={2} >
                <Typography variant="body1" gutterBottom component="h6">
                    SMART Target
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        value={data?.description}
                        disabled
                        label="Description"
                        variant="outlined"
                        sx={{width: '100%'}}
                    />
                    <Box onClick={() => handleClickOpen('description', data?.description, 'Description', false)} sx={{ ml: 2, height: "fit-content", cursor: 'pointer' }}>
                        <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        value={data?.timeline}
                        disabled
                        label="When/Timeline?"
                        variant="outlined"
                        sx={{width: '100%'}}
                    />
                    <Box onClick={() => handleClickOpen('timeline', data?.timeline, 'When/Timeline?', false)} sx={{ ml: 2, height: "fit-content", cursor: 'pointer' }}>
                        <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        value={data?.managerComments}
                        disabled
                        label="Manager comments?"
                        variant="outlined"
                        sx={{width: '100%'}}
                    />
                    <Box onClick={() => handleClickOpen('mannagerComments', data?.managerComments, 'Manager Comments', false)} sx={{ ml: 2, height: "fit-content", cursor: 'pointer' }}>
                        <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                    </Box>
                </Box>

                {yourRating && <Stack
                    mt={2}
                    alignItems={'center'}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Your Rating"} </Typography>

                    <ReactRating readonly initialRating={data?.employeeRating} />

                </Stack>}
                {managerRating && <Stack
                    mt={2}
                    alignItems={'center'}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Manager Rating"} </Typography>

                    <ReactRating readonly initialRating={data?.managerRating} />


                </Stack>}

            </Stack>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        variant="outlined"
                        disabled={!isEditable}
                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                    {isEditable && <Button onClick={handleSave} color="primary">Save</Button>}
                </DialogActions>
            </Dialog>
        </Paper>
    )
}
