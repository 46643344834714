import { useSelector } from "react-redux";
import PerformanceReviewPageForEmployee from "./PerformanceReviewPageForEmployee";
import PerformanceReviewPageForManager from "./PerformanceReviewPageForManger";


export default function PerformanceReviewPage() {


    const { user } = useSelector((state) => state.auth);
    let content = null;

    if (user?.userType === 'E') {
        content = <PerformanceReviewPageForEmployee />
    }
    if (user?.userType === 'M' || user?.userType === 'A') {
        
        content = <PerformanceReviewPageForManager />
    }

    return (<>
        {content}
    </>)

}
