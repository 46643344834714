import React from 'react'
import PropTypes from 'prop-types';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ReadOnlySmartTarget from './ReadOnlySmartTarget';

ReadOnlyObjectAppraisal.propTypes = {
    list: PropTypes.array,
    ratingHidden: PropTypes.bool,
    employeeRatingAvg: PropTypes.number,
    managerRatingAvg: PropTypes.number,
    displayRating: PropTypes.bool
};

export default function ReadOnlyObjectAppraisal({reviewreating, list,sectionno, ratingHidden=false, employeeRatingAvg,managerRatingAvg,displayRating=true}) {

return (
        <Box bgcolor={"#f5f5f5"} p={2}>

            <Box border={"1px solid #ddd"} borderRadius={1} p={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="subtitle1" gutterBottom component="p">
                Outline smart objectives for achievement for next year.
                </Typography>
            </Box>

            {list.map((data, key) =>
                <ReadOnlySmartTarget key={key} data={data} yourRating={!ratingHidden} managerRating={!ratingHidden} />
            )}
            {displayRating && (<TableContainer component={Paper}  >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell align="right">Your Rating</TableCell>
                            <TableCell align="right">Manager Rating</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          
                            <TableCell align="right">{reviewreating?.find(x=>x.reviewRatingQuestionId ===9).employeeRating}</TableCell>
                            <TableCell align="right">{reviewreating?.find(x=>x.reviewRatingQuestionId ===9).managerRating}</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> )}
            

        </Box>
    )
}
