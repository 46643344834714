import { Box, Button, CardActions, Grid, Stack, TextField, Typography, TextareaAutosize } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ReactRating from '../../../components/rating/ReactRating'
import { useUpdateEmployeeRatingMutation } from '../../../features/review/reviewApi'
import TransitionAlert from '../../../components/alert/TransitionAlert'

import { ObjectiveAppraisalForEmployee } from '../performance'
import PopUpModal from '../../../components/modal/PopUpModal'
import textareaAutoHeight from '../../../utils/textareaAutoHeight'

UpdateEmployeeReviewFrom.propTypes = {
    selfApprasal: PropTypes.object,
    RatingQA: PropTypes.object,
    setOpen: PropTypes.func,
    draftData: PropTypes.object,

};
export default function UpdateEmployeeReviewFrom({ task, draftData, selfApprasal, RatingQA, setOpen }) {
    const [draft, setDraft] = React.useState(false)
    const [updateEmployeeRating, { data, isLoading, isSuccess }] = useUpdateEmployeeRatingMutation();

    const initialvaluesSelfApprasal = draftData?.employeeSelfApprasalQAs
        ?.reduce((acc, curr) => ({
            ...acc,
            [`employeeSelfApprasalQAId-${curr?.selfAppraisalQuestionId}`]: curr?.employeeSelfApprasalQAId,
            [`selfApprasal-${curr?.selfAppraisalQuestionId}`]: curr?.answer,
        }), {});

    const initialvaluesEmployeeRate = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeePRRatingId-${curr?.reviewRatingQuestionId}`]: draftData?.employeeRatingModels?.find((item) => item?.reviewRatingQuestionId === curr?.reviewRatingQuestionId)?.employeePRRatingId,
        [`employeeRate-${curr?.reviewRatingQuestionId}`]: draftData?.employeeRatingModels?.find((item) => item?.reviewRatingQuestionId === curr?.reviewRatingQuestionId)?.employeeRating,
    }), {});
    const initialvaluesEmployeeRateComment = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRateComment-${curr?.reviewRatingQuestionId}`]: draftData?.employeeRatingModels?.find((item) => item?.reviewRatingQuestionId === curr?.reviewRatingQuestionId)?.employeeComment,
    }), {});

    const smartTarget = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: draftData?.managerSmartTargetQuestions[curr]?.description,
        [`managerSmartTargetQuestions-timeline-${curr}`]: draftData?.managerSmartTargetQuestions[curr]?.timeline,
        [`managerSmartTargetQuestions-employeeRating-${curr}`]: draftData?.managerSmartTargetQuestions[curr]?.employeeRating,
    }), {});
    const initialvalues = {
        ...initialvaluesSelfApprasal,
        ...initialvaluesEmployeeRate,
        ...initialvaluesEmployeeRateComment,
        ...smartTarget,
        areasIdentifiedforTrainingDevelopment: draftData?.areasIdentifiedforTrainingDevelopment,
    }

    // create validation schema dynamically using the questions array
    const selfSchema = selfApprasal?.data?.reduce((acc, curr) => ({
        ...acc,
        [`selfApprasal-${curr?.selfAppraisalQuestionId}`]: yup.string().required("Required"),
    }), {})
    const ratingSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRate-${curr?.reviewRatingQuestionId}`]: yup.number().min(1, "Required").max(5, "Required").integer("Required").required("Required")
    }), {});
    const commentSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`employeeRateComment-${curr?.reviewRatingQuestionId}`]: yup.string().required("Required"),
    }), {});
    const smartTargetSchema = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-timeline-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-employeeRating-${curr}`]: yup.number().min(1, "Required").max(5, "Required").integer("Required").required("Required"),
    }), {});
    const validationSchema = yup.object({
        ...selfSchema,
        ...ratingSchema,
        ...commentSchema,
        ...smartTargetSchema,
        areasIdentifiedforTrainingDevelopment: yup.string().required("Required"),
    });

    const onSubmit = (values) => {

        let employeeSelfApprasalQAs = Object.keys(values).filter((key) => key.includes("selfApprasal"))
        employeeSelfApprasalQAs = employeeSelfApprasalQAs.map((key) => key.includes("selfApprasal") && {
            selfAppraisalQuestionId: key.split("-")[1],
            answer: values[key],
        })
        // const employeeRate = Object.keys(values).filter((key) => key.includes("employeeRate"))
        const employeeRateComment = Object.keys(values).filter((key) => key.includes("employeeRateComment"))

        const employeeRatingModels = employeeRateComment.map((key) => ({
            reviewRatingQuestionId: key.split("-")[1],
            employeePRRatingId: values[`employeePRRatingId-${key?.split("-")[1]}`],
            employeeRating: values[`employeeRate-${key?.split("-")[1]}`],
            employeeComment: values[`employeeRateComment-${key?.split("-")[1]}`],
        }))

        const managerSmartTargetQuestionsDescription = Object.keys(values).filter((key) => key.includes("managerSmartTargetQuestions-description"));

        const managerSmartTargetQuestions = managerSmartTargetQuestionsDescription.map((key) => ({
            rowNumber: Number(key.split("-")[2]) + 1,
            description: values[`managerSmartTargetQuestions-description-${key?.split("-")[2]}`],
            timeline: values[`managerSmartTargetQuestions-timeline-${key?.split("-")[2]}`],
            employeeRating: values[`managerSmartTargetQuestions-employeeRating-${key?.split("-")[2]}`],

        }))

        updateEmployeeRating({
            employeeReviewDetailsId: draftData?.employeeReviewDetailsId,
            status: draft ? "Draft" : "Submitted",
            dateofReview: new Date(),
            employeeSelfApprasalQAs,
            employeeRatingModels,
            managerSmartTargetQuestions,
            areasIdentifiedforTrainingDevelopment: values.areasIdentifiedforTrainingDevelopment,
        });
    }
    const navigate = useNavigate();
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setDraft(false);
            setTimeout(() => {
                navigate("/dashboard/performance-review");
            }, 1000);


        }
    }, [data?.isSuccess, isSuccess, navigate])

    useEffect(() => {
        textareaAutoHeight();
    }, [])

    const OpeninstructionPDF = (ele) => {
        if (ele !== null && ele.target.name === 'PdfOpen') {
            window.open('/assets/PDF/instruction.pdf');
        }
    }
    return (
        <Formik
            initialValues={initialvalues}
            validationSchema={draft ? null : validationSchema}
            onSubmit={onSubmit}
        >
            {
                ({ handleSubmit }) => (
                    <Form>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

                        <Button name="PdfOpen" onClick={(e) => OpeninstructionPDF(e)} variant="contained" >
                                instruction
                            </Button>
                        </Stack>
                        <Stack spacing={2} width={"100%"}>
                            <Typography variant="h5" color="initial">
                                Self Appraisals
                            </Typography>

                            {selfApprasal?.data?.map((data) =>
                                <Box key={data?.selfAppraisalQuestionId}>
                                    <Field name={`selfApprasal-${data?.selfAppraisalQuestionId}`}>
                                        {({ field, form }) => (
                                            <TextField
                                                sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                fullWidth
                                                multiline
                                                rows={2}
                                                label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                                                {...field}
                                                variant="outlined"

                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name={`selfApprasal-${data?.selfAppraisalQuestionId}`}>
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Box>
                            )}


                        </Stack>
                        <Grid container spacing={3} mt={5}>
                            <Grid item xs={12}>
                                {RatingQA?.data?.map((data, key) =>
                                    <Stack
                                        key={key}
                                        mt={2}
                                        alignItems={'center'}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 1, sm: 1, md: 1 }}
                                    >
                                        <Box maxWidth={"550px"} width={"100%"} >
                                            <Typography variant='subtitle1'> {data.question} </Typography>
                                            <Typography variant='body2'> {data.description} </Typography>
                                        </Box>
                                        <Box maxWidth={"180px"} width={"100%"}>
                                            <Field name={`employeeRate-${data?.reviewRatingQuestionId}`}>
                                                {({ form, field }) => {
                                                    const { setFieldValue } = form;
                                                    const { value } = field;
                                                    return (
                                                        <ReactRating
                                                            initialRating={value}
                                                            onChange={(val) => setFieldValue(`employeeRate-${data?.reviewRatingQuestionId}`, val)}
                                                            value={data.Rating} />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name={`employeeRate-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                        <Box width ={"33%"}>
                                            <Field name={`employeeRateComment-${data?.reviewRatingQuestionId}`}>
                                                {({ field, form }) => (
                                                    <TextField
                                                        sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                        fullWidth
                                                        multiline
                                                        rows={"2"}
                                                        placeholder={data?.Description}
                                                        label="Comments"
                                                        variant="outlined"
                                                        {...field}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage name={`employeeRateComment-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                    </Stack>
                                )}
                            </Grid>

                        </Grid>

                        <Typography variant='h5' >
                            Section 3 - Objectives Appraisal
                        </Typography>
                        <ObjectiveAppraisalForEmployee />

                        <Grid container spacing={3} my={5}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'> Areas Identified for Training & Development - </Typography>
                                <Field name="areasIdentifiedforTrainingDevelopment">
                                    {({ field, form }) => (
                                        <TextField
                                            sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                            fullWidth
                                            multiline
                                            rows={"2"}
                                            {...field}

                                        />
                                    )}
                                </Field>

                                <ErrorMessage name="areasIdentifiedforTrainingDevelopment">
                                    {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                </ErrorMessage>

                            </Grid>
                        </Grid>

                        {task !== undefined ? <>
                            <Grid container spacing={3} my={5} alignItems="center" justifyContent="center">

                                {/* {draftData?.status !== "Submitted" && task.status !== 'Complete' && <Grid item xs={3} >
                                    <Box>
                                        <LoadingButton type='button' onClick={() => setDraft(true)} color='inherit' variant="contained" fullWidth> Draft </LoadingButton>
                                    </Box>

                                </Grid>}

                                {draftData?.status !== "Submitted" && task.status !== 'Complete' && <Grid item xs={3} >
                                    <Box>


                                        <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth> Submit </LoadingButton>

                                    </Box>
                                </Grid>
                                } */}

                                {/* {(draftData?.status === "Submitted" && task.status !== 'Complete') && <Grid item xs={3} >
                                    <Box>


                                        <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth> Save Changes </LoadingButton>

                                    </Box>
                                </Grid>} */}

                                {(draftData?.status === 'RequestForEdit' && task.status === 'Complete') && <Grid item xs={3} >
                                    <Box>


                                        <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth> Save Changes </LoadingButton>

                                    </Box>
                                </Grid>}
                            </Grid>
                        </> : <>
                            <Grid container spacing={3} my={5} alignItems="center" justifyContent="center">

                                {draftData?.status !== "Submitted"  && <Grid item xs={3} >
                                    <Box>
                                        <LoadingButton type='button' onClick={() => setDraft(true)} color='inherit' variant="contained" fullWidth> Draft </LoadingButton>
                                    </Box>

                                </Grid>}

                                {draftData?.status !== "Submitted"  && <Grid item xs={3} >
                                    <Box>


                                        <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth> Submit </LoadingButton>

                                    </Box>
                                </Grid>
                                }

                                {draftData?.status === "Submitted"&& <Grid item xs={3} >
                                    <Box>


                                        <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth> Save Changes </LoadingButton>

                                    </Box>
                                </Grid>}

                               
                            </Grid>
                        </>
                        }




                        <PopUpModal open={draft} setOpen={setDraft}>
                            <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2" mb={2}>
                                Are you sure you want to save as draft?
                            </Typography>
                            <CardActions>
                                <Button type='button' onClick={() => setDraft(false)} color='inherit' fullWidth variant="contained">
                                    Cancel
                                </Button>
                                <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit} fullWidth> save as draft </LoadingButton>
                            </CardActions>
                        </PopUpModal>
                        {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}

                    </Form>
                )
            }
        </Formik >
    )
}
