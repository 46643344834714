import React from 'react'
import { Box } from '@mui/material'
// import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import EmployeeSmartTarget from './EmployeeSmartTaget'

export default function ObjectiveAppraisalForEmployee( {setFieldValue}) {
    const smartTargets = [
        {
            id: 0,
            description: "managerSmartTargetQuestions-description-0",
            timeline: "managerSmartTargetQuestions-timeline-0",
            employeeRating: "managerSmartTargetQuestions-employeeRating-0",
           
        },
        {
            id: 1,
            description: "managerSmartTargetQuestions-description-1",
            timeline: "managerSmartTargetQuestions-timeline-1",
            managerComments: "managerSmartTargetQuestions-managerComments-1",
            employeeRating: "managerSmartTargetQuestions-employeeRating-1",
            managerRating: "managerSmartTargetQuestions-managerRating-1",
        },
        {
            id: 2,
            description: "managerSmartTargetQuestions-description-2",
            timeline: "managerSmartTargetQuestions-timeline-2",
            managerComments: "managerSmartTargetQuestions-managerComments-2",
            employeeRating: "managerSmartTargetQuestions-employeeRating-2",
            managerRating: "managerSmartTargetQuestions-managerRating-2",
        }

    ]
    return (
        <Box >
            {smartTargets.map((data, key) =>
                <EmployeeSmartTarget key={key} data={data} setFieldValue={setFieldValue} />
            )}

            {/* <TableContainer component={Paper}  >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell align="center">Your Rating</TableCell>
                            <TableCell align="center">Manager Rating</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>

                            <TableCell align="center">5</TableCell>
                            <TableCell align="center">4</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> */}

        </Box>
    )
}
