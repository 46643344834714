import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
// import { styled } from '@mui/material/styles';
// components
// import Logo from '../../components/logo';

// ----------------------------------------------------------------------

// const StyledHeader = styled('header')(({ theme }) => ({
//   top: 0,
//   left: 0,
//   lineHeight: 0,
//   width: '100%',
//   position: 'absolute',
//   padding: theme.spacing(3, 3, 0),
//   [theme.breakpoints.up('sm')]: {
//     padding: theme.spacing(5, 5, 0),
//   },
// }));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const { accessToken, user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (accessToken && user?.isFirstLogin) {
       navigate("/dashboard")
    }
   
  }, [accessToken, user, navigate])

  return (
    <>
      {/* <StyledHeader>
        <Logo />
      </StyledHeader> */}

      <Outlet />
    </>
  );
}
