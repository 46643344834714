import React, { useState } from 'react';
import { Grid, TextField, Typography, Stack, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { useGetAnEmployeeRatingQuery, useRatingQAQuery } from '../../../features/review/reviewApi';
import SingleRatingMR from './SingleRatingMR';

ReadOnlyManagerReview.propTypes = {
    list: PropTypes.array,
    id: PropTypes.number.isRequired,
};

export default function ReadOnlyManagerReview({ list, id }) {
    const { data, isLoading, isError, error } = useRatingQAQuery();
    const { data: old } = useGetAnEmployeeRatingQuery(id);
    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [answers, setAnswers] = useState({});
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    let content = null;
    if (isLoading) {
        content = <div>Loading...</div>;
    }
    if (!isLoading && isError) {
        content = <p>{error}</p>;
    }
    if (!isLoading && !isError && data?.data?.length === 0) {
        content = <p>No Found data</p>;
    }

    if (!isLoading && !isError && data?.data?.length > 0 && list?.length > 0) {
        content = data?.data?.map((row, key) => <SingleRatingMR key={key} row={{ ...row, ...list[key] }} id={id} />);
    }

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpen(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [currentField]: dialogContent,
        }));
        setOpen(false);
    };

    return (
        <Grid item xs={12} lg={9}>
            <Typography variant="h5" mb={3} color="initial">
                Self Appraisals
            </Typography>
            {old?.data?.employeeSelfApprasalQAs?.map((data) => (
                <Stack
                    key={data?.selfAppraisalQuestionId}
                    mt={2}
                    alignItems={'center'}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 10, sm: 10, md: 10 }}
                >
                    <Box maxWidth={"650px"} width={"100%"} display={"flex"} alignItems={"center"}>
                        <Box sx={{ display: 'flex', alignItems: 'end', width: "100%" }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                                value={answers[data?.selfAppraisalQuestionId] ?? data?.answer}
                                disabled
                                sx={{ mb: 2, flexGrow: 1 }}
                                variant="outlined"
                            />
                            <Box onClick={() => handleClickOpen(`${data?.selfAppraisalQuestionId}. ${data?.question}`, answers[data?.selfAppraisalQuestionId] ?? data?.answer, data?.question, false)} sx={{ ml: 2, height: "fit-content", cursor: 'pointer', paddingBottom: '18px' }}>
                                <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                            </Box>
                        </Box>
                    </Box>
                    <Box maxWidth={"750px"} width={"100%"} >
                        <Box sx={{ display: 'flex', alignItems: 'end' }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label="Mgr Comments"
                                value={answers[`managerAnswer-${data?.selfAppraisalQuestionId}`] ?? data?.managerAnswer}
                                disabled
                                sx={{ mb: 2, flexGrow: 1 }}
                                variant="outlined"
                            />
                            <Box onClick={() => handleClickOpen(`managerAnswer-${data?.selfAppraisalQuestionId}`, answers[`managerAnswer-${data?.selfAppraisalQuestionId}`] ?? data?.managerAnswer, 'Comments', false)} sx={{ ml: 2, height: "fit-content", cursor: 'pointer', paddingBottom: '18px' }}>
                                <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            ))}
            {content}
            <Grid container spacing={3} my={5}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'> Areas Identified for Training & Development - </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'end' }}>
                        <TextField
                            value={old?.data?.areasIdentifiedforTrainingDevelopment}
                            fullWidth
                            multiline
                            disabled
                        />
                        <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopment', old?.data.areasIdentifiedforTrainingDevelopment, 'Area Identified for Training and Development', false)} sx={{ ml: 2, height: "fit-content", cursor: 'pointer', paddingBottom: '18px' }}>
                            <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        variant="outlined"
                        disabled={!isEditable}
                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                    {isEditable && <Button onClick={handleSave} color="primary">Save</Button>}
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
