import React, { useEffect } from 'react'
import { Button, CardActions, TableCell, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { sentenceCase } from 'change-case'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import Label from '../../../components/label/Label'
import { useRemoveEmployeeMutation } from '../../../features/organization/organizationApi'
import PopUpModal from '../../../components/modal/PopUpModal'

export default function Remove({ id }) {
    const [open, setOpen] = React.useState(false);
    const [removeEmployee, { data, isSuccess,isLoading }] = useRemoveEmployeeMutation()
    const { enqueueSnackbar } = useSnackbar();
    const handleRemove = () => {
        removeEmployee(id)
    }
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            setOpen(false)
        }
        if (!data?.isSuccess && isSuccess) {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    }, [data?.isSuccess, data?.message, enqueueSnackbar, isSuccess])
    return (
        <>
            <TableCell align="right">
                <Label onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }} color={"warning"}>{sentenceCase("Remove")}</Label>
            </TableCell>
            <PopUpModal open={open} setOpen={setOpen}>
                <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2">
                    Are you sure you want to remove this employee?
                </Typography>
                <CardActions>
                    <Button onClick={() => setOpen(false)} fullWidth variant="contained">
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={handleRemove} fullWidth color='error' variant="contained">
                        Remove
                    </LoadingButton>
                </CardActions>
            </PopUpModal>

        </>
    )
}

Remove.propTypes = {
    id: PropTypes.number,
}
