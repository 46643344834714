import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
// components
import Iconify from '../../../components/iconify';


import TransitionAlert from '../../../components/alert/TransitionAlert';
import { useResetPasswordMutation } from '../../../features/userSetting/userSettingApi';
import { userLoggedOut } from '../../../features/auth/authSlice';

// ----------------------------------------------------------------------

export default function SetPasswordForm() {
    const { user } = useSelector((state) => state.auth)
    const { password } = useSelector((state) => state.userSetting)
    const [showPassword, setShowPassword] = useState(false);
    const [resetPassword, { data, isSuccess }] = useResetPasswordMutation()
    const initialValues = {
        newPassword: '',
        confirmPassword: ''
    }
    const validationSchema = Yup.object({
        newPassword: Yup.string("Enter your new password").required('Password is required.'),
        confirmPassword: Yup.string("Enter your confirm password").required('Please retype your password.').oneOf([Yup.ref('newPassword')], 'Your passwords do not match.')
    })
    const usersettingPassword = localStorage.getItem('userSetting');
    const onSubmit = (values) => {
        resetPassword({
            email: user.username,
            oldPassword: password ?? JSON.parse(usersettingPassword).password,
            newPassword: values.newPassword
        })

    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/dashboard");
                dispatch(userLoggedOut());
            }, 1000);
        }
    }, [data?.isSuccess, dispatch, isSuccess, navigate])


    const handleLogout = () => {
        dispatch(userLoggedOut());
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            <Form>
                {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
                <Stack spacing={3}>
                    <Field name="newPassword">
                        {({ field }) => (
                            <TextField
                                {...field}
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </Field>
                    <ErrorMessage name="newPassword">
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                    <Field name="confirmPassword">
                        {({ field }) => (
                            <TextField
                                {...field}
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </Field>
                    
                    <ErrorMessage name="confirmPassword">
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember" label="Remember me" /> */}
                    <Typography component={"p"} onClick={handleLogout} variant="subtitle2" underline="hover">
                        Go to Login ?
                    </Typography>
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                    Set Password
                </LoadingButton>
            </Form>
        </Formik>
    );
}
