import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, TextField, Typography, Button, Dialog, Paper, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import ReactRating from '../../../components/rating/ReactRating';

SmartTarget.propTypes = {
    data: PropTypes.object
};

export default function SmartTarget({ data }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [setFieldValueFn, setSetFieldValueFn] = useState(null);

    const handleClickOpen = (field, content, question, editable, setFieldValue) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
        setSetFieldValueFn(() => setFieldValue);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        setFieldValueFn(currentField, dialogContent);
        setOpenDialog(false);
    };

    return (
        <Paper sx={{ my: 5, border: "1px solid #ddd", p: 2 }}>
            <Stack spacing={2} >
                <Typography variant="body1" gutterBottom component="h6">
                    SMART Target
                </Typography>
                <Box display={"flex"} flexDirection={"column"}>
                    <Field name={data?.description}>
                        {({ field, form }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    sx={ (form.submitCount && form.errors[field.name]) ? { border: "2px solid red", borderRadius: "8px" , width: '100%'}: { width: '100%'} }
                                    label="Description"
                                    variant="outlined"
                                    {...field}
                                />
                                <Box
                                    onClick={() => handleClickOpen(field.name, field.value, 'Description', true, form.setFieldValue)}
                                    sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                                >
                                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                </Box>
                            </Box>
                        )}
                    </Field>
                    <ErrorMessage name={data?.description}>
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    <Field name={data?.timeline}>
                        {({ field, form }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    sx={ (form.submitCount && form.errors[field.name]) ? { border: "2px solid red", borderRadius: "8px" , width: '100%'}: { width: '100%'} }
                                    label="When/Timeline?"
                                    variant="outlined"
                                    {...field}
                                />
                                <Box
                                    onClick={() => handleClickOpen(field.name, field.value, 'When/Timeline?', true, form.setFieldValue)}
                                    sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                                >
                                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                </Box>
                            </Box>
                        )}
                    </Field>
                    <ErrorMessage name={data?.timeline}>
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                    <Field name={data?.managerComments}>
                        {({ field, form }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    sx={ (form.submitCount && form.errors[field.name]) ? { border: "2px solid red", borderRadius: "8px" , width: '100%'}: { width: '100%'} }
                                    label="Manager comments?"
                                    variant="outlined"
                                    {...field}
                                />
                                <Box
                                    onClick={() => handleClickOpen(field.name, field.value, 'Manager Comments', true, form.setFieldValue)}
                                    sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                                >
                                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                </Box>
                            </Box>
                        )}
                    </Field>
                    <ErrorMessage name={data?.managerComments}>
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Box>

                {data?.employeeRating &&
                    <Stack
                        mt={2}
                        alignItems={'center'}
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Your Rating"} </Typography>
                        <Field name={data?.employeeRating}>
                            {({ form, field }) => {
                                const { setFieldValue } = form;
                                const { value } = field;
                                return (
                                    <ReactRating
                                        // onChange={value => handleRating(value, data?.reviewRatingQuestionId)}
                                        initialRating={Number(value)}
                                        onChange={(val) => setFieldValue(data?.employeeRating, val)}
                                    />
                                )
                            }}
                        </Field>
                        <ErrorMessage name={data?.employeeRating}>
                            {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                        </ErrorMessage>
                    </Stack>}
                {data?.managerRating && <Stack
                    mt={2}
                    alignItems={'center'}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Manager Rating"} </Typography>
                    <Field name={data?.managerRating}>
                        {({ form, field }) => {
                            const { setFieldValue } = form;
                            const { value } = field;
                            return (
                                <ReactRating
                                    // onChange={value => handleRating(value, data?.reviewRatingQuestionId)}
                                    initialRating={Number(value)}
                                    onChange={(val) => setFieldValue(data?.managerRating, val)}
                                />
                            )
                        }}
                    </Field>
                </Stack>
                }
            </Stack>

            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        variant="outlined"
                        disabled={!isEditable}
                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                    {isEditable && <Button onClick={() => handleSave(setFieldValueFn)} color="primary">Save</Button>}
                </DialogActions>
            </Dialog>

        </Paper>
    )
}
