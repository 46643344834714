import * as React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);


ToastAlert.propTypes = {
    msg: PropTypes.string,
    type: PropTypes.string,
};


export default function ToastAlert({ msg, type = "success"}) {
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
            
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity={type} onClose={handleClose}>
                <AlertTitle>Success</AlertTitle>
                {msg}
            </Alert>
            </Snackbar>
     
    );
}
