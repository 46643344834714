/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Collapse,
} from '@mui/material';
import { Link } from 'react-router-dom';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
import { useGetTaskQuery, useDeleteTaskMutation } from '../features/tasks/tasksApi';




// ----------------------------------------------------------------------

// const TABLE_HEAD = [
//     { id: 1, label: 'Name', alignRight: false },
//     { id: 2, label: 'Location', alignRight: false },
//     { id: 3 },
//     { id: 4 },
//     { id: 5 },
//     { id: 6 },
// ];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.assignedToName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TaskPage() {

    const { data } = useGetTaskQuery()
    const USERLIST = data?.data || []
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST?.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;
    // const [opencollapse, setOpencollapse] = useState(false);

    return (
        <>
            <Helmet>
                <title> Organisation | PEL Group </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Box>
                        <Typography variant="h4" >
                            Tasks
                        </Typography>
                        <Typography variant="body1">
                            { } Members
                        </Typography>
                    </Box>
                    {/* <Link to={"add"} style={{ textDecoration: "none" }}>
                        <Button variant="contained" startIcon={<Iconify icon="material-symbols:person-add" />}>
                            Add New
                        </Button>
                    </Link> */}
                </Stack>

                <Card>
                    <UserListToolbar labelname="Search Tasks.." numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                {/* <UserListHead
                                    order={order}
                                    // orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                /> */}
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => <Row key={key} row={row} />)}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        {/* <br /> Try checking for typos or using complete words. */}
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={USERLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>


        </>
    );
}




function Row(props) {
    const { row } = props;
    const { assignedTo, taskName, status, assignedToName, requesterName } = row || {};
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell width={"20px"}>
                    {/* <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <Iconify icon="material-symbols:keyboard-arrow-up" /> : <Iconify icon="material-symbols:keyboard-arrow-down" />}
                    </IconButton> */}
                </TableCell>

                <TableCell component="th" scope="row" padding="none">
                    <Typography variant="subtitle2" noWrap>
                        {assignedToName}
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                    <Typography variant="subtitle2" noWrap>
                        {requesterName}
                    </Typography>
                </TableCell>

                <TableCell align="left">{status}</TableCell>
                <TableCell align="left">{taskName}</TableCell>

                

                {/* <TableCell align="right">
                    <Actions id={assignedTo} />
                </TableCell> */}
            </TableRow>

        </>
    )
}

Row.propTypes = {
    row: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        designation: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,

    }).isRequired,
};


const Actions = ({ id }) => {
    const [deleteTask, { data }] = useDeleteTaskMutation()
    const [open, setOpen] = useState(null);
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };
    const handleDelete = () => {
        deleteTask(id)
    }
    return (
        <>
            <IconButton onClick={handleOpenMenu} size="large" color="inherit" >
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
            {/* <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                
                 <Link to={`/dashboard/employee/update/${id}`} style={{ textDecoration: "none" }}>
                    <MenuItem>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                    </MenuItem>
                </Link>

                <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover> */}
        </>
    )
}

Actions.propTypes = {
    id: PropTypes.string.isRequired,
};