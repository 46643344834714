// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const adminConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
  },
  {
    title: 'Previous Reviews',
    path: '/dashboard/previous-reviews',
    icon: icon('ic_history'),
  },
  {
    title: 'Performance Review',
    path: '/dashboard/performance-review',
    icon: icon('ic_review'),
  },
  {
    title: 'Organisation',
    path: '/dashboard/organisation',
    icon: icon('ic_usergroup'),
  },

  {
    title: 'Employees',
    path: '/dashboard/employee',
    icon: icon('ic_addUser'),
  },
  // {
  //   title: 'Tasks',
  //   path: '/dashboard/task',
  //   icon: icon('ic_addUser'),
  // },
];
const managerConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
  },
  {
    title: 'Previous Review',
    path: '/dashboard/previous-reviews',
    icon: icon('ic_history'),
  },
  {
    title: 'Performance Review',
    path: '/dashboard/performance-review',
    icon: icon('ic_review'),
  },
  // {
  //   title: 'Organisation',
  //   path: '/dashboard/organisation',
  //   icon: icon('ic_usergroup'),
  // },
  // {
  //   title: 'new Employee',
  //   path: '/dashboard/employee',
  //   icon: icon('ic_addUser'),
  // },
];
const employeeConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
  },
  {
    title: 'Previous Review',
    path: '/dashboard/previous-reviews',
    icon: icon('ic_history'),
  },
  {
    title: 'Performance Review',
    path: '/dashboard/performance-review',
    icon: icon('ic_review'),
  },
];

export { adminConfig, managerConfig, employeeConfig };
