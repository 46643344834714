
import { Link, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useForgotPasswordByLinkMutation, useValidateResetPasswordTokenQuery } from '../../../features/auth/authApi';

// components
import Iconify from '../../../components/iconify';
import TransitionAlert from '../../../components/alert/TransitionAlert';

// ----------------------------------------------------------------------

export default function ResetPasswordFrom() {
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const token = location?.search?.split("=")[1]
    const { data: valid, isSuccess: isSuccesss } = useValidateResetPasswordTokenQuery(token);
    if (!valid?.isSuccess && isSuccesss) {
        navigate("/auth/forgot-password");
    }

    const [forgotPasswordByLink, { data, isSuccess }] = useForgotPasswordByLinkMutation()
    const initialValues = {
        password: '',
        oldPassword: ''
    }
    const validationSchema = Yup.object({
        password: Yup.string("Enter your current password").required("Password is required").min(6, "Must have atleast 6 characters"),
        oldPassword: Yup.string("Enter your current password").required('Please retype your password.').oneOf([Yup.ref('password')], 'Your passwords do not match.')
    })
    const onSubmit = (values) => {
        forgotPasswordByLink({
            userDetailId: valid?.data,
            newPassword: values.password
        });
    }
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/auth/login");
            }, 1000);
        }
    }, [data?.isSuccess, isSuccess, navigate])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            <Form>
                {data?.message && <TransitionAlert msg={data.message} type={data?.isSuccess ? "success" : "warning"} />}
                <Stack spacing={3}>
                    <Field name="password">
                        {({ field }) => (
                            <TextField
                                {...field}
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </Field>
                    <ErrorMessage name="password">
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>

                    <Field name="oldPassword">
                        {({ field }) => (
                            <TextField
                                {...field}
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </Field>
                    <ErrorMessage name="oldPassword">
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember" label="Remember me" /> */}
                    <Typography component={Link} to={"/auth/login"} variant="subtitle2" underline="hover">
                        Go to Login
                    </Typography>
                </Stack>

                <LoadingButton sx={{ mt: 1 }} fullWidth size="large" type="submit" variant="contained">
                    Continue
                </LoadingButton>
            </Form>
        </Formik>
    );
}
