import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Stack, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import ReactRating from '../../../components/rating/ReactRating';

EmployeeSmartTarget.propTypes = {
    data: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export default function EmployeeSmartTarget({ data, setFieldValue }) {

    const [open, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);

    const handleClickOpen = (field, content, title) => {
        // eslint-disable-next-line no-debugger
        debugger;
        setCurrentField(field);
        setDialogTitle(title);
        setDialogContent(content);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        setFieldValue(currentField, dialogContent);
        setOpenDialog(false);
    };

    return (

        <Paper sx={{ my: 5, border: "1px solid #ddd", p: 2 }}>
            <Stack spacing={2}>
                <Typography variant="body1" gutterBottom component="h6">
                    SMART Target
                </Typography>

                <Box display={"flex"} flexDirection={"column"}>
                    <Field name={data?.description}>
                        {({ field, form }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    sx={(form.submitCount && form.errors[field.name]) ? { border: "2px solid red", borderRadius: "8px", width: '100%' } : { borderRadius: "8px", width: '100%' }}
                                    label="Description"
                                    variant="outlined"
                                    {...field}
                                />

                                <Box onClick={() => handleClickOpen(data?.description, field.value, 'Description')} sx={{ ml: 2, height: "fit-content", cursor: 'pointer' }}>
                                    <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                                </Box>
                            </Box>
                        )}
                    </Field>

                    <ErrorMessage name={data?.description}>
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Box>

                <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%' }}>
                    <Field name={data?.timeline}>
                        {({ field, form }) => (
                            <Box id="hello" sx={{ display: 'flex', alignItems: 'center' , width: '100%' }}>
                                <TextField
                                    sx={(form.submitCount && form.errors[field.name]) ? { border: "2px solid red", borderRadius: "8px", width: '100%' } : { borderRadius: "8px", width: '100%' }}
                                    label="When/Timeline?"
                                    variant="outlined"
                             
                                    {...field}
                                />
                                <Box onClick={() => handleClickOpen(data?.timeline, field.value, 'When/Timeline?')} sx={{ ml: 2, height: "fit-content", cursor: 'pointer' }}>
                                    <img src="/assets/icons/edit.svg" alt='edit' width="20px" height="20px" />
                                </Box>
                            </Box>
                        )}
                    </Field>

                    <ErrorMessage name={data?.timeline}>
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Box>

                <Stack
                    mt={2}
                    alignItems={'center'}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >

                    <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Your Rating"} </Typography>
                    <Field name={data?.employeeRating}>
                        {({ form, field }) => {
                            const { setFieldValue } = form;
                            const { value } = field;
                            return (
                                <ReactRating
                                    initialRating={Number(value)}
                                    onChange={(val) => setFieldValue(data?.employeeRating, val)}
                                />
                            );
                        }}
                    </Field>

                    <ErrorMessage name={data?.employeeRating}>
                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                    </ErrorMessage>
                </Stack>
            </Stack>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        variant="outlined"
                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </DialogActions>

            </Dialog>

        </Paper>

    );

}

