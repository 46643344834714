import Select from 'react-select';
import { Field } from 'formik'
import React from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '@mui/material';


SingleSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    // label: PropTypes.string.isRequired,
}

export default function SingleSelect({
    name,
    options,
    label,
}) {
    return (
        <FormControl sx={{ mt: 2, minWidth: 120, width: "100%" }} size="small">
            
            <Field name={name}>
                {({ field, form }) => {
                    const { setFieldValue } = form
                    const { value } = field;
                    return (
                        <Select
                            classNamePrefix="select"
                            defaultValue={value}
                            placeholder={label}
                            isClearable
                            isSearchable
                            name="color"
                            onChange={value => setFieldValue(name, value?.value)}
                            options={options}
                        />
                    )
                }
                }
            </Field>
        </FormControl>
    )
}
