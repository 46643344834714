import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, TextField, Card, IconButton, InputAdornment } from '@mui/material';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import TransitionAlert from '../components/alert/TransitionAlert';
import Iconify from '../components/iconify/Iconify';
import { useResetPasswordMutation } from '../features/userSetting/userSettingApi';



// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
    const [showPassword, setShowPassword] = useState(false);
    const [resetPassword, { data, isLoading }] = useResetPasswordMutation()
    const { username } = useSelector(state => state.auth.user)
    const initialValues = {
        oldPassword: '',
        password: '',
        confirmPassword: ''
    }
    const validationSchema = Yup.object({
        oldPassword: Yup.string().required("Old Password is required"),
        password: Yup.string("Enter your current password").required("New Password is required").min(6, "Must have atleast 6 characters"),
        confirmPassword: Yup.string("Enter your current password").required('Please retype your password.').oneOf([Yup.ref('password')], 'Your passwords do not match.')
    })
    const onSubmit = (values, { resetForm }) => {
        resetPassword({
            ...values,
            email: username,
            newPassword: values.password,
        });
        resetForm();
    }
    return (
        <>
            <Helmet>
                <title> Reset Password | PEL Group </title>
            </Helmet>

            <Container  maxWidth="sm">
                <Typography variant="h3" textAlign={"center"} gutterBottom >
                    Reset Password
                </Typography>
                {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    <Form>
                        <Card>
                            <Grid px={2} py={4} container spacing={3} >

                                <Grid item xs={12} display={"flex"} direction={"column"}>
                                    <Field name="oldPassword">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Old password"
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="oldPassword">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item xs={12} display={"flex"} direction={"column"}>
                                    <Field name="password">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="New password"
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="password">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item xs={12} display={"flex"} direction={"column"}>
                                    <Field name="confirmPassword">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Confirm password"
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="confirmPassword">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={8}>
                                    <LoadingButton loading={isLoading} type='submit' fullWidth variant="contained">
                                        Change Password
                                    </LoadingButton>
                                </Grid>


                            </Grid>
                        </Card>

                    </Form>
                </Formik>
            </Container>
        </>
    );
}
