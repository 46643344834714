import { Box, TableCell, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { sentenceCase } from 'change-case'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';


import Label from '../../../components/label/Label'
import PopUpModal from '../../../components/modal/PopUpModal'
import { useGetEmployeeQuery } from '../../../features/employee/employeeApi';
import SingleSelect from '../../../components/select/SingleSelect';
import { useReplaceOrgMutation } from '../../../features/organization/organizationApi';
import TransitionAlert from '../../../components/alert/TransitionAlert';


const ReplaceForm = ({ id, options, setOpen }) => {
    const [replaceOrg, { data, isSuccess, isLoading }] = useReplaceOrgMutation()
    const { enqueueSnackbar } = useSnackbar();
    const initialValues = {
        newOrganizationId: '',
    }
    const validationSchema = Yup.object({
        newOrganizationId: Yup.string(),
    })
    const onSubmit = (values) => {
        replaceOrg({ ...values, currentOrganizationId: id });
    }
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setOpen(false)
            enqueueSnackbar(data?.message, { variant: 'success' });
        }
    }, [data?.isSuccess, data?.message, enqueueSnackbar, isSuccess, setOpen])
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            <Form>
                <Typography variant="h6" gutterBottom component="h3">
                    Replace new Manager
                </Typography>
                {!data?.isSuccess && isSuccess && <TransitionAlert msg={data?.message} type='error' />}
                <Box>
                    <SingleSelect name="newOrganizationId" label="Manager Name" options={options} />
                </Box>

                <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                    <LoadingButton type="submit" variant="contained" loading={isLoading} sx={{ mt: 2, mb: 1 }}>
                        Replace
                    </LoadingButton>
                </Box>
            </Form>
        </Formik>
    )
}
ReplaceForm.propTypes = {
    id: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })),
    setOpen: PropTypes.func,
}
export default function Replace({ id }) {
    const { data } = useGetEmployeeQuery()
    const managers = data?.data?.filter((item) => item?.userType === "M")
    const options = managers?.map((item) => ({ label: `${item.firstName} ${item?.lastName}`, value: item?.userId }))
    const [open, setOpen] = useState(false)
    const handleModal = () => {
        setOpen(!open)
    }
    return (
        <>
            <TableCell align="right">
                <Label onClick={handleModal} sx={{ cursor: 'pointer' }} color={"warning"}>{sentenceCase("Replace")}</Label>
            </TableCell>
            <PopUpModal open={open} setOpen={setOpen}>
                <ReplaceForm id={id} options={options} setOpen={setOpen} />
            </PopUpModal>
        </>
    )
}


Replace.propTypes = {
    id: PropTypes.number,
}
