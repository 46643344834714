import { apiSlice } from "../api/apiSlice";

const url = "/api/Task"

export const taksApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getEmployeeTask: builder.query({
            query: (id) => `/api/Task/Employee/${id}`,
            keepUnusedDataFor: 600,
            providesTags: ["EmployeeTask"],
        }),
        getTask: builder.query({
            query: () => url,
            keepUnusedDataFor: 600,
            providesTags: ["Task"],
        }),
        getAnTask: builder.query({
            query: (id) => `${url}/${id}`,
            keepUnusedDataFor: 600,
            providesTags:["TaskData"]
        }),
        getAnTaskBYDesUser: builder.query({
            query: (id) => `/api/Task/SelfApprasalQA/${id}`,
            keepUnusedDataFor: 600,
            providesTags:["TaskData"]
        }),

        addTask: builder.mutation({
            query: (data) => ({
                url,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Task"],
            
        }),

        updateTask: builder.mutation({
            query: (data) => ({
                url,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Task"],
            
        }),
        deleteTask: builder.mutation({
            query: (id) => ({
                url: `${url}/${id}`,
                method: "DELETE",

            }),
            invalidatesTags: ["Task"],

        }),











    }),
})


export const {
    useGetEmployeeTaskQuery,
    useGetTaskQuery,
    useGetAnTaskQuery,
    useGetAnTaskBYDesUserQuery,
    useAddTaskMutation,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
} = taksApi