import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Iconify from '../iconify/Iconify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 1080,
    width: "100%",
    maxHeight: "calc(100vh - 150px)",
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,

};
// const DetailsFields = [
//     {
//         'Title': "Name",
//     },
//     {
//         'Title': "Job Role & Department",
//     },
//     {
//         'Title': "Appraiser Name & Job Title:",
//     },
//     {
//         'Title': "Period Covered by Review",
//     },
//     {
//         'Title': "Date of Review",
//     }
// ]
// const appraisalsFields = [
//     {
//         'Title': "1. What do you feel has been your most important achievement(s) in your role this year?",
//     },
//     {
//         'Title': "2. What tasks do you think you could have performed better in the past year? - Give reasons for your answer",
//     },
//     {
//         'Title': "3. What obstacles, if any, prevented you from completing your tasks effectively? ",
//     },
//     {
//         'Title': "4. Do you feel you received sufficient support & training from your Manager/Supervisor/Colleagues during the year? Please give reasons for your answers. ",
//     },
// ]


InstructionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};
export default function InstructionModal({ open, setOpen }) {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <Card sx={style} >
                    <Box position={"absolute"} right={12} top={12} sx={{ cursor: 'pointer' }}>
                        <Iconify icon="mdi:close" onClick={handleClose} />
                    </Box>
                    <Box sx={{ overflowY: 'auto', height: "100%", width: "100%", mt: 4 }}>
                        {/* <Stack spacing={2} mb={3} width={"100%"}>
                            <Typography variant="h5" color="initial">
                                Details
                            </Typography>
                            {DetailsFields.map((data, key) =>

                                <TextField
                                    fullWidth
                                    key={key}
                                    label={data?.Title}
                                    variant="outlined"
                                />
                            )}
                        </Stack>

                        <Stack spacing={2} width={"100%"}>
                            <Typography variant="h5" color="initial">
                                Self Appraisals
                            </Typography>

                            {appraisalsFields.map((data, key) =>

                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    key={key}
                                    label={data?.Title}
                                    variant="outlined"
                                />
                            )}


                        </Stack> */}
                        <Typography>
                            The performance evaluation and appraisal form are tools to provide you the employee with feedback pertaining to your performance for the current review period and to set goals for the upcoming year ahead. Managers and employees will have the opportunity to discuss job tasks, identify developmental areas, encourage and recognise strengths, and develop an action plan for meeting future goals.
                        </Typography>
                        <br />
                        <Typography>
                            In order for the performance evaluation form to be effective and interactive, you the employee will complete a “self-evaluation form” prior to the review. This type of open and honest dialogue encourages positive relationships and ensures that we are striving for constant incremental improvement.
                        </Typography><br />
                        <Typography>
                            In order to provide a clear measurement of performance, the rating system is listed below; Please ensure you read, review and understand the measurement system before completing your self-appraisal, any help and assistance with this can be found via your line manager or any member of the HR team.
                        </Typography><br />
                        <Typography>
                            *Don’t forget to also reference the supporting Employee Guide – How to Self-Appraise
                        </Typography>
                        <TableContainer component={Paper} sx={{ mt: 5 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Self-Appraisal Scoring:</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[
                                        "5 OU – I consistently exceed expectations. I display at all time, without exception, a consistently high level of related skills in my role, in my abilities in my initiative, and in my productivity.",
                                        "4 EE I often exceed expectations. I display a high level of related skills, abilities, initiative, and productivity in my role, exceeding requirements in some areas, but not consistently or not without exception.  ",
                                        "3 ME – I meets expectations.  I display and maintain an effective and consistent level of performance of the job role under review.  ",
                                        "2 NI – I believe some Improvements are needed. I can display inconsistency in the performance of the job role under review and output can fall below acceptable levels.  Tasks may be significantly late at times or incomplete.",
                                        "1 UN – I believe major improvement is needed My work output is consistently low, and I am regularly unable to meet required objective in the role."
                                    ].map((row, id) => (
                                        <TableRow
                                            key={id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography color={"red"} px={2} mt={2}>*Further and more in-depth guidance on how to complete your review can be found in the accompanying Employee Guide Self Appraising Your Performance.</Typography>
                    </Box>
                </Card>
            </>
        </Modal>
    );
}
