import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
// components
import Iconify from '../../../components/iconify';

import { useAuthenticateMutation } from '../../../features/auth/authApi';
import TransitionAlert from '../../../components/alert/TransitionAlert';
import { setpassword } from '../../../features/userSetting/userSettingSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [authenticate, { data, isSuccess,error }] = useAuthenticateMutation()
  const initialValues = {
    email: '',
    password: '',
  }
  const validationSchema = Yup.object({
    email: Yup.string().email("Enter a valid email address").required("Email is required"),
    password: Yup.string("Enter your current password").required("Password is required"),
   
  })
  const dispatch = useDispatch()
  const onSubmit = (values) => {
      dispatch(setpassword(values.password))
      authenticate(values);
    
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (data?.isFirstLogin && isSuccess) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    }
  }, [data?.isFirstLogin, isSuccess, navigate])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      <Form>
        {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
        {error?.data && <TransitionAlert msg={error?.data} type={"warning"} />}
        <Stack spacing={3}>
          <Field name="email">
            {({ field }) => (
              <TextField {...field} label="Email address" />
            )}
          </Field>
          <ErrorMessage name="email">
            {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
          </ErrorMessage>
          <Field name="password">
            {({ field }) => (
              <TextField
                {...field}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
          <ErrorMessage name="password">
            {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
          </ErrorMessage>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          {/* <Checkbox name="remember" label="Remember me" /> */}
          <Typography component={Link} to={"/auth/forgot-password"} variant="subtitle2" underline="hover">
            Forgot password?
          </Typography>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Sign in
        </LoadingButton>
      </Form>
    </Formik>
  );
}
