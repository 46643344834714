import React, { useState } from 'react'
import { Box, Stack, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import { useGetAnEmployeeRatingQuery } from '../../../features/review/reviewApi'
import ReactRating from '../../../components/rating/ReactRating';

SingleRatingQA.propTypes = {
    row: PropTypes.object,
    id: PropTypes.number,
};

export default function SingleRatingQA({ row, id, setFieldValue }) {
    const { data, isLoading, isError, error } = useGetAnEmployeeRatingQuery(id);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        setFieldValue(currentField, dialogContent)
        setOpenDialog(false);
    };

    let content = null;
    if (isLoading) {
        content = <div>Loading...</div>
    }
    if (!isLoading && isError) {
        content = <p>{error}</p>
    }
    if (!isLoading && !isError && data?.data?.employeeRatingModels?.length === 0) {
        content = <p>No Found data</p>
    }

    if (!isLoading && !isError && data?.data?.employeeRatingModels?.length > 0) {
        const result = data?.data?.employeeRatingModels?.find((item) => item.reviewRatingQuestionId === row.reviewRatingQuestionId)

        content = <Stack
            mt={2}
            alignItems={'center'}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
        >
            <Box maxWidth={"350px"} width={"100%"} >
                <Typography variant='subtitle1'> {row.question} </Typography>
                <Typography variant='body2'> {row.description} </Typography>
            </Box>

            <Box>
                <ReactRating readonly initialRating={result?.employeeRating || 0} />
            </Box>
            <Typography variant='subtitle1'> Comments: </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    sx={{ width: 300 }}
                    disabled
                    multiline
                    rows={2}
                    placeholder={row?.Description}
                    variant="outlined"
                    value={result?.employeeComment}
                />
                <Box
                    onClick={() => handleClickOpen('employeeComment', result?.employeeComment, 'Comments', false)} 
                    sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                >
                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                </Box>
            </Box>


            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        value={dialogContent}
                        onChange={(e) => setDialogContent(e.target.value)}
                        variant="outlined"
                        disabled={!isEditable}
                        InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                    {isEditable && <Button onClick={() => handleSave(setFieldValue)} color="primary">Save</Button>}
                </DialogActions>
            </Dialog>

        </Stack>
    }
    return (
        <>
            {content}

        </>
    )
}