import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer } from '@mui/material';
// mock
// hooks
import { useSelector } from 'react-redux';
import useResponsive from '../../../hooks/useResponsive';
// components
// import Logo from '../../../components/logo';
// import logo from '../../../../assets/'
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import { adminConfig, employeeConfig, managerConfig } from './config'

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// const StyledAccount = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: Number(theme.shape.borderRadius) * 1.5,
//   backgroundColor: alpha(theme.palette.grey[500], 0.12),
// }));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const [navConfig, setNavConfig] = useState([]);
  const { user } = useSelector((state) => state?.auth);
  useEffect(() => {
    switch (user?.userType) {
      case 'A':
        setNavConfig(adminConfig);
        break;
      case 'M':
        setNavConfig(managerConfig);
        break;
      case 'E':
        setNavConfig(employeeConfig);
        break;
      default:
        break;
    }

  }, [user?.userType]);
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 2, display: 'inline-flex' }}>
        <img src="/assets/logo.jpg" alt="logo" />
      </Box> */}


      {
        user?.location === 'PEL' ? (
          <><Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
            <img src="/assets/Pellogo.jpeg" alt="logo" />
          </Box></>
        ) : user?.location === 'CEC' ? (
          <><Box sx={{ px: 2.5, py: 5, display: 'inline-flex'}}>
            <img src="/assets/CEClogo.jpg" alt="logo" />
          </Box></>
        ) : user?.location === 'QuipTech' ? (<><Box sx={{ px: 2.5, py: 2, display: 'inline-flex'}}>
          <img src="/assets/Quiptechlogo.jpg" alt="logo" />
        </Box></>) : <>
          <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
            <img src="/assets/logo.jpg" alt="logo" />
          </Box>
          <Box sx={{ display: 'block', textAlign: 'center', mb: -2, color: 'black' }}>
            {user?.location}
          </Box>

        </>
      }
      {/* </Box> */}



      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box> */}

      <NavSection data={navConfig} />

      {/* <Box sx={{ flexGrow: 1 }} /> */}

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
