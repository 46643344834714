import React from 'react'
import PropTypes from 'prop-types'
import Rating from 'react-rating'


ReactRating.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialRating: PropTypes.number.isRequired,
    readonly: PropTypes.bool,
};

export default function ReactRating({ onChange, initialRating, readonly }) {

    return (
        <Rating
            readonly={readonly}
            className='rating'
            initialRating={Number(initialRating)}
            onChange={onChange}
            emptySymbol={[1, 2, 3, 4, 5].map(n => <div key={n} className="empty"> <span>{n}</span> </div>)}
            fullSymbol={[1, 2, 3, 4, 5].map(n => <div key={n} className={`full ${n === initialRating && "activestar"}`}><span>{n}</span></div>)}
        />
    )
}
