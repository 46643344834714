// import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";


export const reviewApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        selfApprasalQA: builder.query({
            query: () => "/api/RatingsQuestions/SelfApprasalQA",
            keepUnusedDataFor: 600,
            providesTags: ["SelfApprasalQA"],
        }),
        ratingQA: builder.query({
            query: () => "/api/RatingsQuestions/RatingQA",
            keepUnusedDataFor: 600,
            providesTags: ["RatingQA"],
        }),
        getEmployeeRating: builder.query({
            query: () => "/api/EmployeeRating",
            keepUnusedDataFor: 600,
            providesTags: ["EmployeeRating"],
        }),
        getAllEmployee: builder.query({
            query: () => "/api/GetAllEmployee",
            keepUnusedDataFor: 600,
            providesTags: ["AllEmployee"],
        }),
        getAnEmployeeRating: builder.query({
            query: (id) => `/api/EmployeeRating/${id}`,
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => [{ type: "EmployeeRating", id: arg?.id }],
        }),
        getAnEmployeePdf: builder.query({
            query: (id) => `/api/GetEmployeeReviewPdf/${id}`,
            keepUnusedDataFor: 600,
            providesTags: ["EmployeeRating","getAnEmployeeRating"],
        }),
        employeeRating: builder.mutation({
            query: (data) => ({
                url: '/api/EmployeeRating',
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["EmployeeRating"], 
        }),
        updateEmployeeRating: builder.mutation({
            query: (data) => ({
                url: '/api/EmployeeRating',
                method: "PUT",
                body: data,
            }),
            invalidatesTags:(result, error, arg) => [{ type: "EmployeeRating", id: arg?.id }],
        }),
        editEmployeeRating: builder.mutation({
            query: (data) => ({
                url: '/api/EditEmployeeRating',
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["EmployeeRating"],
          
        }),
        EditManagerRating: builder.mutation({
            query: (data) => ({
                url: '/api/manager/EditManagerRating',
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["EmployeeRating"],
          
        }),
        getPreviousReviewsEmployees: builder.query({
            query: () => "/api/GetPreviousReviewsEmployees",
            keepUnusedDataFor: 600,
            providesTags: ["PreviousReviewsEmployees"],
        }),

    }),
})


export const {
    useSelfApprasalQAQuery,
    useRatingQAQuery,
    useGetEmployeeRatingQuery,
    useGetAnEmployeeRatingQuery,
    useEmployeeRatingMutation,
    useUpdateEmployeeRatingMutation,
    useGetAnEmployeePdfQuery,
    useEditEmployeeRatingMutation,
    useEditManagerRatingMutation,
    useGetAllEmployeeQuery,
    useGetPreviousReviewsEmployeesQuery,
 } = reviewApi