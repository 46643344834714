import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    IconButton,
    Box,
    Collapse,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Replace from './Replace';
import AddSubOrdinate from './AddSubOrdinate';
import Remove from './Remove';
import RemoveOrganization from './RemoveOrganization';


export default function Row(props) {
    const { row } = props;
    const { organizationName, organizationId, designation } = row || {};
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell width={"20px"}>
                    {row?.employees.length > 0 && <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <Iconify icon="material-symbols:keyboard-arrow-up" /> : <Iconify icon="material-symbols:keyboard-arrow-down" />}
                    </IconButton>}
                </TableCell>

                <TableCell component="th" scope="row" padding="none">
                    <Stack direction="row" ml={1} alignItems="center" spacing={2}>

                        <Box >
                            <Typography variant="subtitle2" noWrap>
                                {organizationName}
                            </Typography>
                            <Typography variant="caption" noWrap>
                                {designation}
                            </Typography>
                        </Box>
                    </Stack>
                </TableCell>


                <Replace id={organizationId} />
                <AddSubOrdinate id={organizationId} name={organizationName} />
                <RemoveOrganization id={organizationId} />


            </TableRow>
            <TableRow>
                <TableCell style={{ padding: "0", }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>

                            <Table size="small" aria-label="purchases">

                                <TableBody >
                                    {row?.employees?.map((data, id) => (
                                        <TableRow key={id}>
                                            <TableCell width={"100px"} height={"60px"}>{" "}</TableCell>

                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" ml={1} alignItems="center" spacing={2}>

                                                    <Box >
                                                        <Typography variant="subtitle2" noWrap>
                                                            {data?.firstName} {data?.lastName}
                                                        </Typography>
                                                        <Typography variant="caption" noWrap>
                                                            {data?.designation}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </TableCell>



                                            <Remove id={data?.userId} />


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

Row.propTypes = {
    row: PropTypes.shape({
        organizationName: PropTypes.string.isRequired,
        organizationId: PropTypes.number.isRequired,
        employees: PropTypes.array,
    }).isRequired,
};