import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// sections
import { ForgotPasswordForm } from '../sections/auth/forgot';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: "#DBDDFA",
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),

}));

// ----------------------------------------------------------------------

export default function ForgotPage() {
    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <Helmet>
                <title> Login | PEL Group </title>
            </Helmet>

            <StyledRoot>
                {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

                {mdUp && (
                    <StyledSection>
                        <img src="/assets/logo.jpg" alt="login" style={{ mixBlendMode: "color-burn" }} />
                        <Typography variant="h5" sx={{ px: 5, mt: 1, mb: 5 }}>
                            Peformance Management System
                        </Typography>
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                           Forgot Password
                        </Typography>


                        <ForgotPasswordForm />
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
