import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, Typography,TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';


import { useGetEmployeeRatingQuery, useRatingQAQuery, useEditManagerRatingMutation, useGetAnEmployeeRatingQuery } from '../features/review/reviewApi';
import ReadOnlyEmployeReview from '../sections/@dashboard/review/ReadOnlyEmployeReview';
import ManagerReviewForm from '../sections/@dashboard/review/ManagerReviewForm';
import { useGetAnManagerRatingQuery } from '../features/manager/managerApi';
import ReadOnlySmartTargets from '../sections/@dashboard/review/ReadOnlySmartTargets';
import PerformanceReviewPageForEmployee from './PerformanceReviewPageForEmployee';
import PopUpModal from '../components/modal/PopUpModal';
import UpdateMangerReviewForm from '../sections/@dashboard/review/UpdateMangerReviewForm';
import { useGetAnTaskQuery } from '../features/tasks/tasksApi';






const EmployeeReviewDate = ({ id, empId, color, self })=>{
    const { data: employeeRating, isSuccess: is } = useGetAnEmployeeRatingQuery(id);
    
    function getDate(date) {
        if (!date) return date;
        const newDate = new Date(date);
        const day = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();
        return `${day}/${month}/${year}`
    }
    return (<div>{color === "green" && !self ? `Submitted Date: ${getDate(employeeRating?.data?.dateofReview) || "" }` : ""}</div>);
}


const Info = ({ id, empId }) => {
    const { data: employeeRating, isSuccess: is } = useGetAnEmployeeRatingQuery(id);
    // console.log(employeeRating);
    const [open, setOpen] = useState(false)
    const { data: RatingQA, isSuccess: ratingSuccess, isLoading: ratingIsLoading } = useRatingQAQuery();
    const { data: managerRatingInfo, isSuccess } = useGetAnManagerRatingQuery(empId);

    const [EditManagerRating, { data: EditRequestRating, isLoading, isSuccess: isuccesss }] = useEditManagerRatingMutation();
    const { data: taskData } = useGetAnTaskQuery(empId);
    const handleEditRequest = () => {
        EditManagerRating({
            EmployeeManagerReviewDetailsId: managerRatingInfo?.data?.employeeManagerReviewDetailsId,
            status: "RequestForEdit",
            EmployeeId: empId,
        });
        setTimeout(() => {
            window.location.reload();
        }, 1000);

    }
    useEffect(() => {
        if (isuccesss && EditRequestRating?.isSuccess) {
            setOpen(false)
        }
    }, [EditRequestRating?.isSuccess, isuccesss])


    let content = null;
    if ((managerRatingInfo?.data?.managerRatingModels?.length > 0 || managerRatingInfo?.data?.managerSmartTargets?.length) && isSuccess) {
        content =
            <>
                {
                    managerRatingInfo?.data?.status === 'Draft' || (managerRatingInfo?.data?.status === 'RequestForEdit' && taskData?.data?.status === 'Complete' && managerRatingInfo?.data !== undefined) ? <>
                        {is && <UpdateMangerReviewForm task={taskData?.data} employeeRating={employeeRating?.data} draftData={managerRatingInfo?.data} id={id} empId={empId} RatingQA={RatingQA} />}
                    </>
                        :
                        <>
                            <ReadOnlySmartTargets id={id} managerRatingInfo={managerRatingInfo?.data} />
                            
                            {/* <Grid container >
                                <Grid item xs={6} mt={3}>
                                    <LoadingButton disabled={!isSuccess} onClick={() => setOpen(true)} variant="contained" fullWidth>Request HR to edit</LoadingButton>
                                </Grid>
                            </Grid> */}

                            {
                                taskData?.data?.status === 'Deny' && <Typography variant='h5' >
                                    <Box display={"flex"} flexDirection={"column"}>

                                        <TextField
                                            disabled
                                            variant="outlined"
                                            value={taskData?.data?.taskName}
                                        />

                                    </Box>
                                </Typography>
                            }

                            {(managerRatingInfo?.data?.status !== 'RequestForEdit' && managerRatingInfo?.data !== undefined) &&
                                <Grid container >
                                    <Grid item xs={6}>
                                        <LoadingButton disabled={!isSuccess} onClick={() => setOpen(true)} variant="contained" fullWidth>Request HR to edit</LoadingButton>
                                    </Grid>
                                </Grid>
                            }
                            <PopUpModal
                                open={open}
                                setOpen={setOpen}
                            >
                                <Typography variant='h5' textAlign={"center"} color={"red"}>
                                    Are you sure?
                                </Typography>
                                <Typography variant='subtitle2' textAlign={"center"} mb={3}>
                                    You want to request HR to edit this review.
                                </Typography>
                                <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                                    <Button onClick={() => setOpen(false)} variant="contained" color='inherit'>No</Button>
                                    <LoadingButton onClick={handleEditRequest} loading={isLoading} variant="contained" >Yes</LoadingButton>
                                </Box>

                            </PopUpModal>
                        </>
                }
            </>

    }
    if ((!managerRatingInfo?.data?.managerRatingModels?.length && !managerRatingInfo?.data?.managerSmartTargets?.length) && ratingSuccess && !ratingIsLoading) {
        content = <>

            <ManagerReviewForm RatingQA={RatingQA} id={id} empId={empId} />


        </>
    }


    return (
        <>
            {content}
        </>
    )
}


Info.propTypes = {
    id: PropTypes.number.isRequired,
    empId: PropTypes.number.isRequired,
}


export default function PerformanceReviewPageForManager() {

    const { user } = useSelector((state) => state.auth)
    // const { data } = useGetEmployeeQuery()
    const { data: employeeRatingList } = useGetEmployeeRatingQuery();
    // const [notFound, setNotFound] = useState(false)
    const [self, setSelf] = useState(false)
    const [id, setId] = useState(undefined)
    const [empId, setEmpId] = useState(undefined)
    const [empColor, setEmpColor] = useState(undefined)

    const [EmployeeObjective, setEmployeeObjective] = useState('employeeRating')

    const handleChange = (panel) => (event, isExpanded) => {
        // setExpanded(isExpanded ? panel : false);

        if (document.getElementById('panel2bh-headersec2').classList.contains('Mui-expanded')) {
            document.getElementById('panel2bh-headersec2').click();
        }
        if (document.getElementById('panel2bh-headersec3').classList.contains('Mui-expanded')) {
            document.getElementById('panel2bh-headersec3').click();
        }
        if (document.getElementById('panel2bh-headersec4').classList.contains('Mui-expanded')) {
            document.getElementById('panel2bh-headersec4').click();
        }

        switch (panel) {
            case "employeeRating":
                setEmployeeObjective(isExpanded ? panel : false);
                break;
            default:
                break;
        }
    };


    const handleUsers = (event) => {
        employeeRatingList.data.forEach((e) => {
            if (event.target.value === e.employeeReviewDetailsId){
                setEmpId(e.employeeId);
                setEmpColor(e.color);
            }
        });
        setId(event.target.value);
    };

    const handleSelf = () => {
        setSelf(val => !val)
        setId(undefined);
        setEmpId(undefined);
        setEmpColor(undefined);
        // setNotFound(false)
        // if (!self) {
        //     const exits = employeeRatingList?.data?.find((e) => e.employeeId === user?.id)
        //     console.log(exits);
        //     if (exits) {

        //         setId(exits.employeeReviewDetailsId);
        //         setEmpId(exits.employeeId);
        //     } else {
        //         setNotFound(true)
        //     }
        // } else {
        //     setId(undefined);
        //     setEmpId(undefined);
        //     setNotFound(false)
        // }
    }

    return (
        <>
            <Helmet>
                <title> Performance Review | PEL Group </title>
            </Helmet>

            <Box position={"relative"} bgcolor={"#FFF"}>
                <Box sx={{

                    top: 90,
                    zIndex: 100,
                    py: 3,
                    bgcolor: "white",
                    width: "100%",
                }}>

                    <Container sx={{
                        margin: 0,
                        display: "flex",
                        width: "100%",
                    }}>

                        <Stack width={"100%"} direction="row" flex={1} alignItems="center" justifyContent={'space-between'} >

                            <FormControlLabel

                                control={
                                    <Switch checked={self} onChange={handleSelf} name="gilad" />
                                }
                                label={self ? "Self View" : "Manager View"}
                            />

                            <EmployeeReviewDate id={id} empId={empId} color={empColor} self={self} />

                            {!self && <FormControl sx={{ m: 1, minWidth: 150 }} size="small" >
                                <InputLabel id="demo-select-small-label">Employee</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={id}
                                    label="Employee"
                                    onChange={handleUsers}
                                >
                                    {employeeRatingList?.data?.map((item, key) => item?.color !== 'white' && <MenuItem key={key} value={item.employeeReviewDetailsId}
                                        sx={{ color: item.color === 'green' ? 'green' : (item.color === 'red' ? 'red' : '#FFBF00'), cursor: item.disabled ? 'pointer' : 'not-allowed', '&:hover': { backgroundColor: 'white' }, backgroundColor: '#fdfdfd' }}
                                        disabled={item.color === 'red'}
                                    >
                                        <span>{`${item.employeeName}`}</span>
                                    </MenuItem>)}

                                </Select>
                            </FormControl>}

                        </Stack>
                    </Container>
                </Box>

                {id && empId && <>
                    {/* <Accordion expanded={EmployeeObjective === 'employeeRating'} onChange={handleChange('employeeRating')}>
                        <AccordionSummary
                            aria-controls="panel2bh-content"
                            id="panel2bh-headersec1"
                        >
                            <Typography variant='h5' >
                                Section 1 - Employee Rating
                            </Typography>
                        </AccordionSummary>
                         <AccordionDetails>
                            {id && <ReadOnlyEmployeReview id={id} empId={empId} />}
                        </AccordionDetails> 
                    </Accordion> */}
                    <Info id={id} empId={empId} />


                </>}

                {self && <>
                    {
                        user?.managerId !== 0 ? <PerformanceReviewPageForEmployee self={self} /> : <Box sx={{ width: "500px", height: '500px', m: '0 auto', display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant='h5' textAlign={"center"} display={'flex'} justifyContent={"center"}>
                                You don't have any manager assigned. You can't review yourself.
                            </Typography>
                        </Box>
                    }
                </>
                }
            </Box>
        </>
    );
}

