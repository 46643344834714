import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { Box, Card } from '@mui/material';
import Iconify from '../iconify/Iconify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'visible',
};

PopUpModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    children: PropTypes.node,
};
export default function PopUpModal({ open, setOpen, children }) {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={style}>
                <Box position={"absolute"} right={14} top={12} sx={{ cursor: 'pointer' }}>
                    <Iconify icon="mdi:close" onClick={handleClose} />
                </Box>

                {children}


            </Card>
        </Modal>
    );
}
