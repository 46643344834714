import { useEffect, useState, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardActions, Grid, Stack, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ManagerToSetWithEmploye, ObjectiveAppraisal } from '../performance';
import ReactRating from '../../../components/rating/ReactRating';
import { useManagerRatingMutation } from '../../../features/manager/managerApi';
import ToastAlert from '../../../components/alert/ToastAlert';
import { useGetAnEmployeeRatingQuery } from '../../../features/review/reviewApi';
import PopUpModal from '../../../components/modal/PopUpModal';

ManagerReviewForm.propTypes = {
    RatingQA: PropTypes.object,
    id: PropTypes.number,
    empId: PropTypes.number,
};

export default function ManagerReviewForm({ RatingQA, id, empId }) {
    console.log(id, empId);
    const { data: employeeRating } = useGetAnEmployeeRatingQuery(id);
    const { user } = useSelector((state) => state.auth);
    const [managerRating, { data, isSuccess, isLoading }] = useManagerRatingMutation();
    console.log(data, isSuccess, isLoading);
    const [managerRate, setManagerRate] = useState(false);
    const [expanded, setExpanded] = useState(['managerRate', 'objective', 'managertoEmployee']);
    const [managertoEmployee, setmanagertoEmployee] = useState(false)
    const [draft, setDraft] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = (setFieldValue) => {
        setFieldValue(currentField, dialogContent)
        setOpenDialog(false);
    };

    // const handleChange = (panel) => {
    //     switch (panel) {
    //         case "objective":
    //             setObjective(val => !val);
    //             setManagerRate(false);
    //             setmanagertoEmployee(false);

    //             break;
    //         case "manager":
    //             setManagerRate(val => !val);
    //             setObjective(false);
    //             setmanagertoEmployee(false);
    //             break;
    //         case "managertoEmployee":
    //             setObjective(false);
    //             setManagerRate(false);
    //             setmanagertoEmployee(val => !val);
    //             break;
    //         default:
    //             break;
    //     }
    // };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded((prevExpanded) =>
            isExpanded
                ? [...prevExpanded, panel]
                : prevExpanded.filter((item) => item !== panel)
        );
    };

    const initialvaluesManagerRate = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRate-${curr?.reviewRatingQuestionId}`]: "",
    }), {});
    const initialvaluesManagerRateComment = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRateComment-${curr?.reviewRatingQuestionId}`]: "",
    }), {});
    const initialvaluesSelfAppraisalManagerComment = employeeRating?.employeeSelfApprasalQAs?.reduce((acc, curr) => ({
        ...acc,
        [`managerSelfAppraisalAnswer-${curr?.selfAppraisalQuestionId}`]: employeeRating?.employeeSelfApprasalQAs?.find((item) => item?.selfAppraisalQuestionId === curr?.selfAppraisalQuestionId)?.managerAnswer,
    }), {});
    const smartTarget = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-managerComments-${curr}`]: "",
        [`managerSmartTargetQuestions-managerRating-${curr}`]: "",
    }), {});
    const smartTarget2 = [3, 4, 5].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: "",
        [`managerSmartTargetQuestions-timeline-${curr}`]: "",
        [`managerSmartTargetQuestions-managerComments-${curr}`]: "",
        // [`managerSmartTargetQuestions-employeeRating-${curr}`]: "",
        // [`managerSmartTargetQuestions-managerRating-${curr}`]: "",
    }), {});
    const initialvalues = {
        ...initialvaluesManagerRate,
        ...initialvaluesManagerRateComment,
        ...initialvaluesSelfAppraisalManagerComment,
        ...smartTarget,
        ...smartTarget2,
        areasIdentifiedforTrainingDevelopmentManagerComment: employeeRating?.areasIdentifiedforTrainingDevelopmentManagerComment,
    }

    // create validation schema dynamically using the questions array

    const ratingSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRate-${curr?.reviewRatingQuestionId}`]: yup.string().required("Required"),
    }), {});
    const commentSchema = RatingQA?.data?.reduce((acc, curr) => ({
        ...acc,
        [`managerRateComment-${curr?.reviewRatingQuestionId}`]: yup.string().required("Required"),
    }), {});

    const smartTargetSchema = [0, 1, 2].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-managerComments-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-managerRating-${curr}`]: yup.string().required("Required"),
    }), {});
    const smartTargetSchema2 = [3, 4, 5].reduce((acc, curr) => ({
        ...acc,
        [`managerSmartTargetQuestions-description-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-timeline-${curr}`]: yup.string().required("Required"),
        [`managerSmartTargetQuestions-managerComments-${curr}`]: yup.string().required("Required"),
    }), {});

    const validationSchema = yup.object({

        ...ratingSchema,
        ...commentSchema,
        ...smartTargetSchema,
        ...smartTargetSchema2,
    });
    const onSubmit = (values) => {
        const managerRateComment = Object.keys(values).filter((key) => key.includes("managerRateComment"))

        const managerRatingModels = managerRateComment.map((key) => {
            const result = employeeRating?.data?.employeeRatingModels?.find((data) => data.reviewRatingQuestionId === Number(key?.split("-")[1]));
            return ({
                reviewRatingQuestionId: key.split("-")[1],
                managerRating: values[`managerRate-${key?.split("-")[1]}`],
                managerComment: values[`managerRateComment-${key?.split("-")[1]}`],
                employeePRRatingId: result?.employeePRRatingId,
            })
        })

        let employeeSelfApprasalQAModels = Object.keys(values).filter((key) => key.includes("managerSelfAppraisalAnswer"))

        employeeSelfApprasalQAModels = employeeSelfApprasalQAModels.map((key) => key.includes("managerSelfAppraisalAnswer") && {
            selfAppraisalQuestionId: key.split("-")[1],
            managerAnswer: values[key],

        })

        const managerSmartTargetQuestionsDescription = Object.keys(values).filter((key) => key.includes("managerSmartTargetQuestions-description"));

        const employeeSmartTargetQuestionsDescription = Object.keys(values).filter((key) => key.includes("managerSmartTargetQuestions-managerRating"));

        const employeeSmartTargetQuestions = employeeSmartTargetQuestionsDescription.map((key) => ({
            rowNumber: key.split("-")[2],
            ...employeeRating?.data?.managerSmartTargetQuestions[key?.split("-")[2]],
            managerComments: values[`managerSmartTargetQuestions-managerComments-${key?.split("-")[2]}`],
            managerRating: values[`managerSmartTargetQuestions-managerRating-${key?.split("-")[2]}`],

        }))

        const managerSmartTargetQuestions = managerSmartTargetQuestionsDescription.map((key) => ({
            rowNumber: key.split("-")[2],
            description: values[`managerSmartTargetQuestions-description-${key?.split("-")[2]}`],
            timeline: values[`managerSmartTargetQuestions-timeline-${key?.split("-")[2]}`],
            managerComments: values[`managerSmartTargetQuestions-managerComments-${key?.split("-")[2]}`],
            managerRating: values[`managerSmartTargetQuestions-managerRating-${parseInt(key?.split("-")[2], 10) - 3}`],
        }))
        console.log('mgremployeeRating', employeeRating)

        // fix manager rating 
        managerRatingModels.forEach(x => {
            if (x.managerRating === "" || x.managerRating === undefined || x.managerRating === null) {
                x.managerRating = 0;
            }
        });
        employeeSmartTargetQuestions.forEach(x => {
            if (x.managerRating === "" || x.managerRating === undefined || x.managerRating === null) {
                x.managerRating = 0;
            }
        });
        managerSmartTargetQuestions.forEach(x => {
            if (x.managerRating === "" || x.managerRating === undefined || x.managerRating === null) {
                x.managerRating = 0;
            }
        });

        managerRating({
            status: draft ? "Draft" : "Submitted",
            employeeId: empId,
            dateofReview: new Date(),
            managerId: user?.id,
            employeeSelfApprasalQAModels,
            managerRatingModels,
            managerSmartTargetQuestions,
            employeeSmartTargetQuestions,
            areasIdentifiedforTrainingDevelopmentManagerComment: values.areasIdentifiedforTrainingDevelopmentManagerComment,
        });
    }

    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setDraft(false);
            window.location.reload();
        }
    }, [data?.isSuccess, isSuccess])


    return (
        <Formik
            initialValues={initialvalues}
            validationSchema={draft ? null : validationSchema}
            onSubmit={onSubmit}
        >
            {
                ({ handleSubmit, setFieldValue }) => (
                    <Form>
                        {data?.isSuccess && isSuccess && <ToastAlert msg={data?.message} />}
                        <Accordion expanded={expanded.includes('managerRate')} onChange={handleChange('managerRate')}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-headersec2"
                            >
                                <Typography variant='h5' >
                                    Section 1 - Employee Rating
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="h5" mb={3} color="initial">
                                    Self Appraisals
                                </Typography>
                                {employeeRating?.data?.employeeSelfApprasalQAs?.map((data) =>
                                    <Stack
                                        key={data?.selfAppraisalQuestionId}
                                        mt={2}
                                        alignItems={'center'}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 10, sm: 10, md: 10 }}
                                    >
                                        <Box maxWidth={"650px"} width={"100%"} sx={{ display: 'flex', alignItems: 'center' }} >
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                maxRows={Infinity}
                                                label={`${data?.selfAppraisalQuestionId}. ${data?.question}`}
                                                value={data?.answer}
                                                disabled
                                                sx={{ mb: 2 }}
                                                variant="outlined"

                                            />
                                            <Box onClick={() => handleClickOpen(`selfApprasal-${data?.selfAppraisalQuestionId}`, data?.answer, data?.question, false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                            </Box>
                                        </Box>
                                        <Box maxWidth={"750px"} width={"100%"} >
                                            <Field name={`managerSelfAppraisalAnswer-${data?.selfAppraisalQuestionId}`}>
                                                {({ field }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            sx={{ mb: 2 }}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                            placeholder={data?.Description}
                                                            label="Mgr Comments"
                                                            variant="outlined"
                                                            {...field}
                                                        />
                                                        <Box onClick={() => handleClickOpen(`managerSelfAppraisalAnswer-${data?.selfAppraisalQuestionId}`, field.value, 'Edit Comment', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Field>

                                        </Box>
                                    </Stack>
                                )}
                                {RatingQA?.data?.map((data, key) =>
                                    <Stack
                                        key={key}
                                        mt={2}
                                        alignItems={'center'}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 1, sm: 1, md: 1 }}
                                    >
                                        <Box maxWidth={"450px"} width={"100%"} >
                                            <Typography variant='subtitle1'> {data.question} </Typography>
                                            <Typography variant='body2'> {data.description} </Typography>
                                        </Box>
                                        <Box maxWidth={"180px"} width={"100%"}>
                                            <ReactRating readonly initialRating={employeeRating?.data?.employeeRatingModels?.find((e) => e.reviewRatingQuestionId === data.reviewRatingQuestionId).employeeRating || 0} />

                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField sx={{ width: 300 }}
                                                disabled
                                                multiline
                                                rows={"2"}
                                                placeholder={data?.Description}
                                                variant="outlined"
                                                value={employeeRating?.data?.employeeRatingModels?.find((e) => e.reviewRatingQuestionId === data.reviewRatingQuestionId)?.employeeComment}
                                            />
                                            <Box onClick={() => handleClickOpen(`employeeRatingModels.${data.reviewRatingQuestionId}.employeeComment`, employeeRating?.data?.employeeRatingModels?.find((e) => e.reviewRatingQuestionId === data.reviewRatingQuestionId)?.employeeComment || '', 'Comment', false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                            </Box>

                                        </Box>
                                        <Box maxWidth={"180px"} width={"100%"}>
                                            <Field name={`managerRate-${data?.reviewRatingQuestionId}`}>
                                                {({ form, field }) => {
                                                    const { setFieldValue } = form;
                                                    const { value } = field;
                                                    return (
                                                        <ReactRating
                                                            // onChange={value => handleRating(value, data?.reviewRatingQuestionId)}
                                                            initialRating={Number(value)}
                                                            onChange={(val) => setFieldValue(`managerRate-${data?.reviewRatingQuestionId}`, val)}
                                                            value={data.Rating} />
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name={`managerRate-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                        <Box maxWidth={"300px"} width={"100%"}>
                                            <Field name={`managerRateComment-${data?.reviewRatingQuestionId}`}>
                                                {({ field, form }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            sx={form.submitCount && form.errors[field.name] && { border: "2px solid red", borderRadius: "8px" }}
                                                            fullWidth
                                                            multiline
                                                            rows={"2"}
                                                            placeholder={data?.Description}
                                                            label="Mgr Comments"
                                                            variant="outlined"
                                                            {...field}
                                                        />
                                                        <Box onClick={() => handleClickOpen(`managerRateComment-${data?.reviewRatingQuestionId}`, field.value, 'Edit Comment', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Field>
                                            <ErrorMessage name={`managerRateComment-${data?.reviewRatingQuestionId}`}>
                                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                            </ErrorMessage>
                                        </Box>
                                    </Stack>
                                )}
                                <Grid container spacing={3} my={5}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'> Areas Identified for Training & Development - </Typography>
                                        <Stack
                                            key={data?.selfAppraisalQuestionId}
                                            mt={2}
                                            alignItems={'center'}
                                            direction={{ xs: 'column', sm: 'row' }}
                                            spacing={{ xs: 10, sm: 10, md: 10 }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <TextField
                                                    value={employeeRating?.data?.areasIdentifiedforTrainingDevelopment}
                                                    fullWidth
                                                    multiline
                                                    disabled
                                                    rows={"2"}
                                                />
                                                <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopmentManagerComment', employeeRating?.areasIdentifiedforTrainingDevelopment, 'Edit Area Identified for Training and Development', false)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                    <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                </Box>
                                            </Box>
                                            <Field name="areasIdentifiedforTrainingDevelopmentManagerComment">
                                                {({ field, form }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <TextField
                                                            rows={"2"}
                                                            fullWidth
                                                            multiline
                                                            sx={{ mb: 2, width: '100%' }}
                                                            variant="outlined"
                                                            {...field}
                                                        />
                                                        <Box onClick={() => handleClickOpen('areasIdentifiedforTrainingDevelopmentManagerComment', field.value, 'Edit Area Identified for Training and Development', true)} sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}>
                                                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Field>

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded.includes('objective')} onChange={handleChange('objective')}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-headersec3"
                            >
                                <Typography variant='h5' >
                                    Section 2 - Objectives Appraisal
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ObjectiveAppraisal empId={empId} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded.includes('managertoEmployee')} onChange={handleChange('managertoEmployee')}>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-headersec4"
                            >
                                <Typography variant='h5' >
                                    Section 3 – Objectives Set for next year
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ManagerToSetWithEmploye />
                            </AccordionDetails>
                        </Accordion>

                        <Grid container spacing={3} my={5}>
                            <Grid item xs={3} >
                                <Box>
                                    <LoadingButton type='button' onClick={() => setDraft(true)} color='inherit' variant="contained" fullWidth> Draft </LoadingButton>
                                </Box>
                            </Grid>
                            <Grid item xs={3} >
                                <Box>
                                    <LoadingButton loading={isLoading} type='submit' variant="contained" fullWidth> Submit </LoadingButton>
                                </Box>
                            </Grid>

                            <PopUpModal open={draft} setOpen={setDraft}>
                                <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2" mb={2}>
                                    Are you sure you want to save as draft?
                                </Typography>
                                <CardActions>
                                    <Button type='button' onClick={() => setDraft(false)} color='inherit' fullWidth variant="contained">
                                        Cancel
                                    </Button>
                                    <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit} fullWidth> save as draft </LoadingButton>
                                </CardActions>
                            </PopUpModal>
                        </Grid>

                        <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                            <DialogTitle>{dialogTitle}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={dialogContent}
                                    onChange={(e) => setDialogContent(e.target.value)}
                                    variant="outlined"
                                    disabled={!isEditable}
                                    InputProps={{ style: { minHeight: '200px', alignItems: 'start' } }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">Close</Button>
                                {isEditable && <Button onClick={() => handleSave(setFieldValue)} color="primary">Save</Button>}
                            </DialogActions>
                        </Dialog>
                    </Form>
                )}
        </Formik>
    )
}
