import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReadOnlyObjectAppraisal from '../performance/ReadOnlyObjectAppraisal';
import ReadOnlyManagerReview from './ReadOnlyManagerReview';
import { useGetAnEmployeeRatingQuery } from '../../../features/review/reviewApi';

ReadOnlySmartTargets.propTypes = {
  managerRatingInfo: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};

export default function ReadOnlySmartTargets({ managerRatingInfo, id }) {
  const { data, isLoading, isSuccess } = useGetAnEmployeeRatingQuery(id);

  const { managerSmartTargets: list, managerRatingModels } = managerRatingInfo || {};
  const [expanded, setExpanded] = useState(['managerRate', 'objective', 'manager']);

  const handleChange = (panel) => (event, isExpanded) => {
    console.log(panel);
    setExpanded((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((item) => item !== panel)
    );
  };

  return (
    <>
      <Accordion expanded={expanded.includes('managerRate')} onChange={handleChange('managerRate')}>
        <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-headersec2">
          <Typography variant="h5">Section 1 - Employee Rating</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReadOnlyManagerReview list={managerRatingModels} id={id} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.includes('objective')} onChange={handleChange('objective')}>
        <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-headersec3">
          <Typography variant="h5">Section 2 - Objectives Appraisal</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!isLoading && isSuccess && (
            <ReadOnlyObjectAppraisal
              sectionno={3}
              reviewreating={managerRatingModels}
              employeeRatingAvg={data?.data?.employeeRatingAvg}
              managerRatingAvg={data?.data?.managerRatingAvg}
              list={data?.data?.managerSmartTargetQuestions.filter(
                (x) => x.employeeRating !== 0 || x.managerRating === 0
              )}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded.includes('manager')} onChange={handleChange('manager')}>
        <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-headersec4">
          <Typography variant="h5">Section 3 – Objectives Set for next year</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {list?.filter((x) => x.employeeRating === 0).length === 0 ? (
            <ReadOnlyObjectAppraisal
              sectionno={4}
              list={list.filter((x) => x.employeeRating === undefined)}
              displayRating={false}
              ratingHidden
            />
          ) : (
            <ReadOnlyObjectAppraisal
              sectionno={4}
              list={list.filter((x) => x.employeeRating === 0 || x.managerRating === 0)}
              displayRating={false}
              ratingHidden
            />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}