import { apiSlice } from "../api/apiSlice";

const url = "/api/Organization"

export const organizationApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getOrganization: builder.query({
            query: () => url,
            keepUnusedDataFor: 600,
            providesTags: ["Organization"],
        }),
        getAnOrganization: builder.query({
            query: (id) => `${url}/${id}`,
            keepUnusedDataFor: 600,
        }),
       
        addOrganization: builder.mutation({
            query: (data) => ({
                url,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Organization","Employee"],
        }),

        addOrgEmployee: builder.mutation({
            query: (data) => ({
                url: `${url}/AddOrgEmployee`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Organization", "AllEmployee","EmployeeRating"],

        }),

        removeEmployee: builder.mutation({
            query: (id) => ({
                url: `/api/Organization/RemoveEmployee/${id}`,
                method: "POST"
            }),
            invalidatesTags: ["Organization","Employee"],

        }),

        replaceOrg: builder.mutation({
            query: (data) => ({
                url: `${url}/ReplaceOrg`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Organization","Employee"],

        }),

       

        deleteOrganization: builder.mutation({
            query: (id) => ({
                url: `${url}/${id}`,
                method: "DELETE",

            }),
            invalidatesTags: ["Organization","Employee"],

        }),











    }),
})


export const {
    useGetOrganizationQuery,
    useGetAnOrganizationQuery,
    useAddOrganizationMutation,
    useAddOrgEmployeeMutation,
    useRemoveEmployeeMutation,
    useReplaceOrgMutation,
    useDeleteOrganizationMutation
} = organizationApi