import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, TextField, Typography, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import ReactRating from '../../../components/rating/ReactRating';

SmartTargetForManager.propTypes = {
    data: PropTypes.object,
    employee: PropTypes.object,
};

export default function SmartTargetForManager({ data, employee }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [currentField, setCurrentField] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    const {setFieldValue} = useFormikContext();

    const handleClickOpen = (field, content, question, editable) => {
        setCurrentField(field);
        setDialogTitle(question);
        setDialogContent(content);
        setOpenDialog(true);
        setIsEditable(editable);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        setFieldValue(currentField, dialogContent);
        setOpenDialog(false);
    };

    if (employee.employeeRating !== 0) {
        return (
            <Paper sx={{ my: 5, border: "1px solid #ddd", p: 2 }}>
                <Stack spacing={2}>
                    <Typography variant="body1" gutterBottom component="h6">
                        SMART Target
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <TextField
                            disabled
                            value={employee?.description}
                            label="Description"
                            variant="outlined"
                            sx={{ width: '100%' }}
                        />
                        <Box
                            onClick={() => handleClickOpen('description', employee?.description, 'Description', false)}
                            sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                        >
                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <TextField
                            disabled
                            value={employee?.timeline}
                            label="When/Timeline?"
                            variant="outlined"
                            sx={{ width: '100%' }}
                        />
                        <Box
                            onClick={() => handleClickOpen('timeline', employee?.timeline, 'When/Timeline?', false)}
                            sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                        >
                            <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Field name={data?.managerComments}>
                            {({ field, form }) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <TextField
                                        sx={ (form.submitCount && form.errors[field.name]) ? { border: "2px solid red", borderRadius: "8px" , width: '100%'}: { width: '100%'} }
                                        label="Manager comments?"
                                        variant="outlined"
                                        {...field}
                                    />
                                    <Box onClick={() => handleClickOpen(field.name, field.value, 'Edit Comments', true)}
                                        sx={{ ml: 2, height: 'fit-content', cursor: 'pointer' }}
                                    >
                                        <img src='/assets/icons/edit.svg' alt="edit" width="20px" height="20px" />
                                    </Box>
                                </Box>
                            )}
                        </Field>
                        <ErrorMessage name={data?.managerComments}>
                            {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                        </ErrorMessage>
                    </Box>
                    <Stack
                        mt={2}
                        alignItems={'center'}
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Your Rating"} </Typography>
                        <ReactRating readonly initialRating={employee?.employeeRating} />
                    </Stack>
                    {data?.managerRating && (
                        <Stack
                            mt={2}
                            alignItems={'center'}
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                        >
                            <Typography maxWidth={"170px"} width={"100%"} variant='subtitle1'> {"Manager Rating"} </Typography>
                            <Field name={data?.managerRating}>
                                {({ field, form }) => {
                                    const { setFieldValue } = form;
                                    const { value } = field;
                                    return (
                                        <ReactRating
                                            initialRating={Number(value)}
                                            onChange={(val) => setFieldValue(data?.managerRating, val)}
                                        />
                                    );
                                }}
                            </Field>
                            <ErrorMessage name={data?.managerRating}>
                                {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                            </ErrorMessage>
                        </Stack>
                    )}
                </Stack>
                <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="lg">
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                       
                        <TextField 
                            fullWidth
                            multiline
                            value={dialogContent}
                            onChange={(e) => setDialogContent(e.target.value)}
                            variant="outlined"
                            disabled={!isEditable}
                            InputProps={{style: { minHeight: '200px', alignItems: 'start' } }}
                        />
                       
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Close</Button>
                        {isEditable && <Button onClick={() => handleSave(setFieldValue)} color="primary">Save</Button>}
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}
