import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';

import Iconify from '../../../components/iconify/Iconify';


ReviewsList.propTypes = {
  list: PropTypes.array,
  id: PropTypes.number,
  setId: PropTypes.func,
};
export default function ReviewsList({ list, id, setId }) {
  return (
    <Box sx={{ width: '100%', maxWidth: 360 }}>
      <List component="nav" aria-label="main mailbox folders">
        {list?.map((value, key) => (
          <ListItemButton key={key}
            selected={id === value.employeeId}
            onClick={() => setId(value)}
          >
            <ListItemIcon>
              <Iconify icon={"ic:baseline-folder"} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={value.employeeName} />
          </ListItemButton>
        ))}

      </List>

    </Box>
  );
}