import React, { useEffect } from 'react'
import { Button, CardActions, TableCell, Typography } from '@mui/material'
import { sentenceCase } from 'change-case'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import Label from '../../../components/label/Label'
import { useDeleteOrganizationMutation } from '../../../features/organization/organizationApi'
import PopUpModal from '../../../components/modal/PopUpModal'

export default function RemoveOrganization({ id }) {
    const [open, setOpen] = React.useState(false);
    const [deleteOrganization,{data,isSuccess,isLoading}] = useDeleteOrganizationMutation()
    const handleRemove = () => {
        deleteOrganization(id)
    }
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            setOpen(false)
        }
        if (!data?.isSuccess && isSuccess) {
            enqueueSnackbar(data?.message, { variant: 'error' });
            setOpen(false)
        }
    }, [data?.isSuccess, data?.message, enqueueSnackbar, isSuccess])
    return (
        <>
            <TableCell align="right">
                <Label onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }} color={"warning"}>{sentenceCase("Remove")}</Label>
            </TableCell>
            <PopUpModal open={open} setOpen={setOpen}>
                <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2">
                    Are you sure you want to remove this employee?
                </Typography>
                <CardActions>
                    <Button onClick={() => setOpen(false)} fullWidth variant="contained">
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={handleRemove} fullWidth color='error' variant="contained">
                        Remove
                    </LoadingButton>
                </CardActions>
            </PopUpModal>
        </>
    )
}

RemoveOrganization.propTypes = {
    id: PropTypes.number,
}
