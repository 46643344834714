
import React,{useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

// @mui
import {
    Stack,
    Button,
    Container,
    Typography,
    Box,
} from '@mui/material';

import { Link, useNavigate} from 'react-router-dom';
// components
import Iconify from '../components/iconify';

import { useGetOrganizationQuery } from '../features/organization/organizationApi';
import OrganizationTable from '../sections/@dashboard/organization/OrganizationTable';

export default function OrganisationPage() {
    const { user } = useSelector((state) => state.auth);
    const { data, isLoading, isError, error } = useGetOrganizationQuery();

    let content = null;
    if (isLoading) {
        content = <div>Loading...</div>
    }
    if (!isLoading && isError) {
        content = <p>{error}</p>
    }
    if (!isLoading && !isError && data?.data?.length === 0) {
        content = <p>No Found data</p>
    }

    if (!isLoading && !isError && data?.data?.length > 0) {
        content = <OrganizationTable USERLIST={data?.data} />
    }
    const navigate = useNavigate();
    useEffect(() => {
        if (user?.userType !== 'A') {
            navigate("/dashboard");
        }
    });
    return (
        <>
            <Helmet>
                <title> Organisation | PEL Group </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Box>
                        <Typography variant="h4" >
                            Organisation
                        </Typography>
                        <Typography variant="body1">
                            {data?.data?.length ?? 0} Managers
                        </Typography>
                    </Box>
                    <Link to={"add"} style={{ textDecoration: "none" }}>
                        <Button variant="contained" startIcon={<Iconify icon="material-symbols:person-add" />}>
                            Add Manager
                        </Button>
                    </Link>
                </Stack>
                {content}
            </Container>
        </>
    )
}
