import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, TextField, Card } from '@mui/material';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useAddEmployeeMutation } from '../features/employee/employeeApi';
import TransitionAlert from '../components/alert/TransitionAlert';
import SingleSelect from '../components/select/SingleSelect';



// ----------------------------------------------------------------------

export default function AddEmployeePage() {
    // const theme = useTheme();
    const [addEmployee, { data, isLoading, isSuccess }] = useAddEmployeeMutation()
    const lstlocation = [{ label:'PEL', value: 'PEL'},{label:'CEC', value: 'CEC'},{label:'QuipTech', value: 'QuipTech'}]
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        designation: '',
        location: '',
    }
    const validationSchema = Yup.object({
        firstName: Yup.string().required("Please enter a first name "),
        lastName: Yup.string().required("Please enter a last name"),
        email: Yup.string().email("Enter a valid email address").required("Please enter an email address"),
        designation: Yup.string().required("Please enter a job title"),
        location: Yup.string().required("Please enter a location"),
    })
    const onSubmit = (values) => {
        addEmployee({
            userId: 1,
            ...values
        });
    }
    const navigate = useNavigate()
    useEffect(() => {
        if (data?.isSuccess && isSuccess) {
            setTimeout(() => {
                navigate("/dashboard/employee");
            }, 1000);
        }
    }, [data?.isSuccess, isSuccess, navigate])



    return (
        <>
            <Helmet>
                <title> Add New Employee | PEL Group </title>
            </Helmet>

            <Container sx={{ margin: "0 0" }} maxWidth="md">

                <Typography variant="h3" >
                    Add New
                </Typography>
                {data?.message && <TransitionAlert msg={data.message} type={data.isSuccess ? "success" : "warning"} />}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    <Form>
                        <Card>
                            <Grid px={2} py={4} container spacing={3} >
                                <Grid item xs={12} sm={6} >
                                    <Field name="firstName">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="First Name" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="firstName">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field name="lastName">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Last Name" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="lastName">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="email">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Email Address"
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="email">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="designation">
                                        {({ field }) => (
                                            <TextField {...field} required fullWidth label="Job Title" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="designation">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>

                                </Grid>

                                <Grid item xs={12}>
                                    <Field name="location">
                                        {({ field }) => (
                                            // <TextField {...field} required fullWidth label="Location" />
                                            <SingleSelect {...field} name="location"  options={lstlocation} label="Company*" />
                                        )}
                                    </Field>
                                    <ErrorMessage name="location">
                                        {msg => <Typography fontWeight={600} color={"red"}>{msg}</Typography>}
                                    </ErrorMessage>

                                </Grid>

                                <Grid item xs={2} />
                                <Grid item xs={8} p={0} sx={{mt: 6}}>
                                    <LoadingButton loading={isLoading} type='submit' fullWidth variant="contained">
                                        Add
                                    </LoadingButton>
                                </Grid>


                            </Grid>
                        </Card>

                    </Form>
                </Formik>
            </Container>
        </>
    );
}
