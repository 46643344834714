import React from 'react'
import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify/Iconify'

ReviewsPdf.propTypes = {
  list: PropTypes.array ,
  id: PropTypes.object ,
};
export default function ReviewsPdf({id, list}) {
  const handleDownload = async(fileUrl) => {
    try {
      // const baseurl=  "https://localhost:7083/"; 
      const baseurl = process.env.REACT_APP_BASE_URL;
      const link = document.createElement('a');
      link.href =`${`${baseurl}File/${fileUrl}/false`}`;
      link.target="_blank";
      link.setAttribute('download', fileUrl);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error occurred while downloading the file:', error);
    }
  };
  return (
    <>
    {list?.map((value,key) => (
          <Box  key={key} display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} mb={4}>
          <Typography variant="h2" sx={{ mb: 2 }}>
          {value.year}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
              <Iconify icon={"bi:file-earmark-pdf-fill"} width={68} height={68} onClick={() => handleDownload(value.employeeReviewDetailId)} />
              {/* <Typography variant="body1" sx={{ mb: 2 }}>
              {value.type}
              </Typography> */}
            </Box>
  
          </Stack>
        </Box>
  
        ))}
      
      {/* <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          2021
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
            <Iconify icon={"bi:file-earmark-pdf-fill"} width={68} height={68} />
            <Typography variant="body1" sx={{ mb: 2 }}>
              H1
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
            <Iconify icon={"bi:file-earmark-pdf-fill"} width={68} height={68} />
            <Typography variant="body1" sx={{ mb: 2 }}>
              H2
            </Typography>
          </Box>
        </Stack>
      </Box> */}
    </>
  )
}
